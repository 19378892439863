<div class="row mt-1">
	<div class="col-6">
		<h6 class="example-h4"  *ngIf="label">{{label | translate}}</h6>
	</div>
	<div class="col-6"  *ngIf="showToggle">
		<a href="javascript:void(0)" class="float-right" (click)="unselectAll()">Unselect all</a> &nbsp;
		<a href="javascript:void(0)" class="float-right mr-2" (click)="selectAll()">Select all</a>
	</div>
</div>

<div class="{{error[field] ? 'form-control' : ''}}" [class.is-invalid]="error[field]" #container>
	<ng-container *ngIf="!useChips">
		<label *ngFor="let option of options;let index = index;">
			<input type="checkbox" [disabled]="disabled" [checked]="isChecked(option)" (change)="update(option,$event)" [name]="field+index"/> {{option.label}}
		</label>	
	</ng-container>
	<ng-container *ngIf="useChips && showChips">
		<mat-chip-list selectable multiple (change)="chipsChanged($event)">
			<mat-chip *ngFor="let option of options;let index = index;" 
								#c="matChip"
								color="primary" 
								[selected]="isChecked(option)"
								[value]="option.value"
								[ngbTooltip]="option.multSelectIcon ? option.label : null"
								(click)="updateChips(option, c)"
			>
				<ng-container *ngIf="!option.multSelectIcon">{{option.label}}</ng-container>
				<ng-container *ngIf="option.multSelectIcon"><i [class]="'fa fa-'+option.multSelectIcon"></i></ng-container>				
			</mat-chip>
		</mat-chip-list>
	</ng-container>
</div>

<div *ngIf="error[field]" class="invalid-feedback">{{error[field]}}</div>
<br/>
