import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { CvEntryDialogComponent } from '../cventry/cventry.component';

@Component({
  selector: 'app-cventry-add',
  templateUrl: './cventryAdd.component.html',
  styleUrls: ['./cventryAdd.component.scss']
})
export class CventryAddDialogComponent {
  @ViewChild('modal', { static: false }) modalItem: ElementRef;


  constructor(
    public dialogRef: MatDialogRef<CventryAddDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dataDialog: any,
    public api:ApiService
  ) {
    
  }

	public options_cv_entry:any = [];
	public isAddNew = false
	public selectedData = { cv_entry: null }

  ngOnInit() {
		this.getData();
	}

	async getData() {
		const options_cv_entry:any = await this.api.request('cv_entry/getAll', {});
		const ids = this.dataDialog.cv_entry_type?.cv_entry.map(e => e.id);
		this.options_cv_entry = options_cv_entry
			.filter(e => e.f_cv_entry_type_id === this.dataDialog.cv_entry_type.id && ids.indexOf(e.id) === -1)
			.map(e => ({...e, value: e.id }));
	}

	async addItem(id) {
		if (this.dataDialog.afterSave) {
			const option = this.options_cv_entry.find(e => e.id === id);
			this.dataDialog.afterSave(option);
		}
	}

	async addNew() {
		const dialog = this.api.dialog.open(CvEntryDialogComponent, {
			data: {
				id: '0',
				afterSave: (data) => {
					this.dataDialog.afterSave(data);
					dialog.close();
				}
			},
		});
	}

}
