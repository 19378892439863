import { Component, OnInit, Input, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { ApiService } from '../../../api.service';

@Component({
	selector: 'sm-select',
	templateUrl: './sm-select.component.html',
	styleUrls: ['./sm-select.component.scss']
})
export class SmSelectComponent implements OnInit {

	@Input() label: string;
	@Input() model: any;
	@Input() disabled: any = false;
	@Input() field: any;
	@Input() error: any = {};
	@Input() options: any = [];
	@Output() change = new EventEmitter();

	constructor(private api:ApiService) { }

	ngOnInit() {}

	runChange() {
		this.change.emit(this.model[this.field]);
	}
}
