<div>
  <h3 mat-dialog-title>
    {{'Insert CV entry' | translate}}
    <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog" tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  
    <button mat-flat-button color="primary" (click)="addNew()">{{'Create new' | translate}}</button>
  </h3>
  
  <div class="container">
  
    <div mat-dialog-content>

      <div class="list-group">
        <a href="javascript:;" (click)="addItem(item.id)" class="list-group-item" *ngFor="let item of options_cv_entry">
          <span class="badge bg-green">{{'+ Add' | translate}}</span> {{item.label}}
        </a>
      </div>
      <div class="alert alert-info" *ngIf="!options_cv_entry.length">
        {{'No items to add' | translate}}
      </div>

    </div>
  </div>  
</div>
