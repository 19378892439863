	<!--ng-multiselect-dropdown-angular7
		[placeholder]="label ? ((label | translate) + '...') : ' '"
		[settings]="dropdownSettings"
		[data]="options"
		[disabled]="disabled"
		[(ngModel)]="selectedItems"
		(onDropDownClose)="update()"
		class="{{error[field] ? 'form-control' : ''}}"
		[class.is-invalid]="error[field]"
	>
	</ng-multiselect-dropdown-angular7-->

	<mat-form-field class="example-full-width" appearance="outline">
		<mat-label *ngIf="label">{{label | translate}}</mat-label>
		<mat-select [(ngModel)]="model[this.field]" (selectionChange)="runChange()">
			<mat-option *ngFor="let option of options" [value]="option.value">{{option.label}}</mat-option>
		</mat-select>
	</mat-form-field>

	<div *ngIf="error[field]" class="invalid-feedback">{{error[field]}}</div>
