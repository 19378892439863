import {Component, OnInit, Input, SimpleChanges, Injectable, Inject} from '@angular/core';
import { formatDate } from '@angular/common';

import * as moment from 'moment';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

const I18N_VALUES = {
  en: {
    weekdays: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
    months: ["Jan", "Feb", "März", "Apr", "Mai", "Juni", "Juli", "Aug", "Sep", "Okt", "Nov", "Dez"],
  },
};

// Define a service holding the language. You probably already have one if your app is i18ned.
@Injectable()
export class I18n {
  language = 'en';
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private _i18n: I18n) {
			super();
	}

	getDayAriaLabel(date) {
		const jsDate = new Date(date.year, date.month - 1, date.day);
		return formatDate(jsDate, 'fullDate', this._i18n.language);
	}

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }
}

export const CUSTOM_DATE_FORMAT = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'sm-date',
  templateUrl: './sm-date.component.html',
  styleUrls: ['./sm-date.component.scss'],
  providers: [
		I18n, 
		{provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
		{provide: DateAdapter, useClass: MomentDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMAT},
	] // define custom NgbDatepickerI18n provider
})
export class SmDateComponent implements OnInit {

	@Input() label:string;
	@Input() model:any;
	@Input() value:any;
	@Input() field:any;
	@Input() error: any = {};
	@Input() disabled: any = false;
	@Input() allowClear: any = false;
	dateContainer:any = {};

  constructor(
		private _adapter: DateAdapter<any>,
		@Inject(MAT_DATE_LOCALE) private _locale: string,) {
			this.initDate();
			this._adapter.setLocale("de-CH");
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.value) {
			this.initDate();
		}
	}

	initDate() {
    if (this.allowClear && !this.value) {
      this.clear();
      return;
    }		
		let date = new Date();
		if (this.value) {
			date = new Date(this.value);
		}
		this.dateContainer = this.value ? date : null;//{year: date.getFullYear(), month: date.getMonth()+1, day: date.getDate()};	
	}

  ngOnInit() {
		this.initDate();
	}

  clear() {
    this.dateContainer = null;
    this.setNewDate();
  }

	setNewDate() {
		this.model[this.field] = this.dateContainer ? moment(this.dateContainer).format("YYYY-MM-DD") : '';
	}

}
