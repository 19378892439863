import { Component, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: []
})
export class MainLayoutComponent implements OnInit {
  constructor(public api: ApiService) {}
  public showApp = false;

  async ngOnInit(): Promise<void> {
    await firstValueFrom(this.api.translationsLoaded());
    setTimeout(() => {
      this.showApp = true;
    }, 0);
  }
}
