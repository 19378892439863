import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SmInputPasswordRoutingModule } from './sm-input-password-routing.module';
import { SmInputPasswordComponent } from './sm-input-password.component';

import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';


@NgModule({
  declarations: [SmInputPasswordComponent],
  imports: [
		NgbModule, TranslateModule, FormsModule, MatFormFieldModule, MatInputModule,


    CommonModule,
    SmInputPasswordRoutingModule
	],
	exports: [
		SmInputPasswordComponent
	]
})
export class SmInputPasswordModule { }
