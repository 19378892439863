import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-image-edit-dialog',
  templateUrl: './imageEdit.component.html',
  styleUrls: ['./imageEdit.component.scss']
})
export class ImageEditDialogComponent {
  @ViewChild('modal', { static: false }) modalItem: ElementRef;

	public data:any = {
		public: '0'
  };
	error:any = {}
	success: boolean = false
	options_f_category_id:any = []

  constructor(
    public dialogRef: MatDialogRef<ImageEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dataDialog: any,
    public api:ApiService
  ) {
    
  }


  async ngOnInit() {
		this.options_f_category_id = await this.api.request('category/forSelect', {});
		this.data.f_user_id = this.api.user.id;
		this.getData();
	}

	async getData() {
    if (this.dataDialog.id != '0') {
			this.data = await this.api.request("user_image/getOne", {id: this.dataDialog.id});
		}
	}

	async save() {
		this.success = false;
		var resp:any = await this.api.request("user_image/save", this.data);
		if (resp.error) {
			this.error = resp.error;
		}
		if (resp.success) {
			this.success = true;
			this.data.id = resp.id;
			this.error = {};
			this.api.snackSuccess(this.api.trns('Image have bees succesfully saved!'));
			if (this.dataDialog.afterSave) {
				this.dataDialog.afterSave(this.data);
			}
		}
	}

}
