<div>
  <h3 mat-dialog-title>
    <ng-container *ngIf="!data.id">{{'New' | translate}} </ng-container>{{'Document' | translate}}
    <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog" tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  
    <button mat-flat-button color="primary" (click)="save()">Save</button>
  </h3>
  
  <div class="container">
  
    <div mat-dialog-content>
      <sm-select label="Document type" [(model)]="data" field="f_document_type_id" [(error)]="error" [options]="options_f_document_type_id"></sm-select>
      <sm-select-multiple label="Personal category" [(model)]="data" field="personal_category" [(error)]="error" [options]="options_personal_category"></sm-select-multiple>
      <sm-file label="Upload" [(model)]="data" field="upload" [(error)]="error"></sm-file>
      <sm-input label="Label" [(model)]="data" field="label" [(error)]="error"></sm-input>
      <sm-html label="Short description" [(model)]="data" field="shortdescription" [(error)]="error"></sm-html>
      <sm-html label="Long description" [(model)]="data" field="longdescription" [(error)]="error"></sm-html>
    </div>
  </div>  
</div>
