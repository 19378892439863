import { Component, OnInit, Input } from '@angular/core';

import Base64Upload from '../../../../assets/js/Base64Upload.js';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'sm-html',
  templateUrl: './sm-html.component.html',
  styleUrls: ['./sm-html.component.scss']
})
export class SmHtmlComponent implements OnInit {

	@Input() label:string;
	@Input() model:any;
	@Input() field:any;
	@Input() error: any = {};
	@Input() disabled: any = false;

	public ckConfig = { extraPlugins: [Base64Upload] }
	public Editor = ClassicEditor;

  constructor() {}

  ngOnInit() {
		if (typeof this.model[this.field] == "undefined" || !this.model[this.field]) {
			this.model[this.field] = "";
		}
  }

}
