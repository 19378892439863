<div class="form-group">
	<label class="form-control-label" [for]="field">{{label | translate}}</label>
	<div *ngIf="error[field]" class="invalid-feedback">{{error[field]}}</div>

	<div [id]="'orderForm_'+field" [dragula]='"bag"' [(dragulaModel)]="reorderModel">
		<div class="card" *ngFor="let input of inputs;let index = index;">
			<input type="hidden" [value]="input.id" [alt]="index" />
			<div class="card-body">
        <button (click)="delete(index)" class="btn btn-primary"><i class="fa fa-trash"></i></button>
        <i class="fa fa-bars"></i> &nbsp;
        <input [(ngModel)]="input.name" (ngModelChange)="syncData()" [name]="field" type="text" class="form-control" placeholder="{{label | translate}}">
		  </div>
		</div>
	</div>

  <br/>
  <button (click)="addItem()" class="btn btn-primary"><i class="fa fa-plus"></i></button>

</div>
