<div>
  <h3 mat-dialog-title>
    <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog" tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  
    <div *ngIf="!data.cvData.saved" ngbDropdown class="d-inline-block cvBtnContainer" #cvPopDrop="ngbDropdown">
      <button mat-flat-button color="primary" ngbDropdownToggle>{{'Save as' | translate}}</button>
      <div ngbDropdownMenu aria-labelledby="dropdownForm3" id="dropdownForm3">
        <div class="p-2" style="min-width: 600px">
            <sm-input label="Title" [(model)]="data.cvData" field="titleSave"></sm-input>
          <div class="dropdown-divider"></div>
          <button type="button" class="btn btn-success no-after" [disabled]="!data.cvData.titleSave" (click)="cvPopDrop.close();saveAs();"><i class="fa fa-save"></i> {{'Save' | translate}}</button>
        </div>
      </div>
    </div>

    <button mat-flat-button class="btn btn-success no-after" (click)="save();"><i class="fa fa-save"></i> {{'Save' | translate}}</button>

    <button mat-flat-button color="accent" (click)="preview = !preview" tabindex="-1">{{'Preview' | translate}}</button>
  
    <!--button mat-flat-button color="basic" (click)="print('cv')" tabindex="-1">{{'Print' | translate}}</button-->
  </h3>
  
  <div mat-dialog-content>
    <div id="cvBody" [class.isPreview]="preview" [class.noPreview]="!preview"></div>
  </div>
</div>
