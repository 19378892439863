import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { ApiService } from '../../../api.service';
import { FormBuilder, FormGroup } from  '@angular/forms';
import { DragulaService } from 'ng2-dragula';
import { Subscription } from 'rxjs';

@Component({
  selector: 'sm-input-multiple',
  templateUrl: './sm-input-multiple.component.html',
  styleUrls: ['./sm-input-multiple.component.scss']
})
export class SmInputMultipleComponent implements OnInit {

	@Input() label:string;
	@Input() model:any;
	@Input() field:any;
	@Input() error: any = {};
	@Input() disabled: any = false;
	uploadResponse:any = {}
	inputs:any = []
	reorderModel:any = []
	subs = new Subscription();

  constructor(public api: ApiService, private formBuilder: FormBuilder, private dragulaService: DragulaService) {

	}

  ngOnInit() {
		this.subs.add(this.dragulaService.drop()
		.subscribe(({ name, el, target, source, sibling }) => {
			let newOrder = [];
			let inputs = document.querySelectorAll(`#orderForm_${this.field} input[type=hidden]`);
			inputs.forEach((item:any)=>{
				newOrder.push(this.inputs.find(e => e.id == item.value));
			});
			this.inputs = newOrder;
			this.syncData();
		})
	);
	}

  addItem() {
    let newInput:any = {id: Math.round(Math.random()*999999999999), name: ""};
		this.inputs.push(newInput);
    this.syncData();
  }

	ngOnChanges(changes: SimpleChanges) {
		this.initData();
	}

	initData() {
		this.inputs = [];
		if (this.model[this.field]) {
			let splitted = this.model[this.field].split(",");
			for (let input of splitted) {
				this.inputs.push({id: Math.round(Math.random()*999999999999), name: input, uploadResponse:{}});
			}
		}
	}

	delete(index) {
		this.inputs.splice(index, 1);
		this.syncData();
		return false;
	}

	syncData() {
		this.model[this.field] = this.inputs.map(item => item.name).join(",");
		//console.log('sync', this.model[this.field]);
	}

}
