<!--div class="form-group">
	<label class="form-control-label" [for]="field">{{label | translate}}</label>
	<div class="input-group datepicker-input">
		<input class="form-control" (dateSelect)="setNewDate()" (click)="d.toggle()" placeholder="yyyy-mm-dd" [name]="field" [(ngModel)]="dateContainer" [class.is-invalid]="error[field]" readonly ngbDatepicker #d="ngbDatepicker">
    <button (click)="clear()"><span class="fa fa-times"></span></button>
		<button class="input-group-addon" (click)="d.toggle()" type="button">
			<span class="fa fa-calendar"></span>
		</button>
		<div *ngIf="error[field]" class="invalid-feedback">{{error[field]}}</div>
	</div>
</div-->

<mat-form-field class="example-full-width" appearance="outline">
	<mat-label>{{label | translate}}</mat-label>
	<input matInput [matDatepicker]="picker" [name]="field" [(ngModel)]="dateContainer" (dateChange)="setNewDate()">
	<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
	<mat-datepicker #picker></mat-datepicker>
</mat-form-field>
