import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ApiService } from 'src/app/api.service';
import { CvEntryDialogComponent } from '../cventry/cventry.component';
import { CventryAddDialogComponent } from '../cventryAdd/cventryAdd.component';

@Component({
  selector: 'app-cvdisplay',
  templateUrl: './cvdisplay.component.html',
  styleUrls: ['./cvdisplay.component.scss']
})
export class CvDisplayDialogComponent implements OnInit {
	public saved:any = []
	user:any = {}
	images:any = {}
  templateData:any = {}
  template:string = ''
  image = ''
  date = moment().format("MMMM Do YYYY")
  public preview = false
	public languages:any = [];
	public userlanguages:any = [];
	public options_f_language_skill_id:any = []

  constructor(
    public dialogRef: MatDialogRef<CvDisplayDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public api:ApiService
  ) {
  }

  async ngOnInit() {
		const languages:any = await this.api.request("language/getForSkills", { id: this.api.user.id });
		this.languages = languages.map(l => ({label: l.name, value: l.code, image: l.image}));
		this.options_f_language_skill_id = await this.api.request('language_skill/forSelect', {});
		this.userlanguages = await this.api.request("user/getLanguageSkills");		

    this.setTemplateCv(true);

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  print(containerId:string) {
    this.api.print(document.getElementById(containerId).innerHTML);
  }

  async refresh() {
  }

  async save() {
    const html = document.getElementById('cv').innerHTML;

    let saveData:any = {
      type: 'cv', 
      title: this.data.cvData.title, 
      data: JSON.stringify(this.data.cvData),
      html
    };

    if (this.data.cvData.id) {
      saveData.id = this.data.cvData.id;
    }

    await this.api.request("saved/save", saveData);
    if (this.data.afterSave) {
      this.data.afterSave();
    }
    this.api.snackSuccess("Cv have bees succesfully saved!");
  }

  async saveAs() {
    const html = document.getElementById('cv').innerHTML;

    let saveData:any = {
      type: 'cv', 
      title: this.data.cvData.titleSave, 
      data: JSON.stringify(this.data.cvData),
      html
    };

    await this.api.request("saved/save", saveData);
    if (this.data.afterSave) {
      this.data.afterSave();
    }
    this.api.snackSuccess("Cv have bees succesfully saved!");
  }

  updateCvData(data) {
    this.data.cvData = data;
    console.log('updateCvData', data);
  }

  async setTemplateCv(doRefresh:boolean = false) {
    if (doRefresh) {
      await this.refresh();
    }

    console.log('cvData',this.data.cvData);

    document.getElementById('cvBody').innerHTML = `<div id="cv" ng-controller="cvController" ng-cloak></div>`;

    const templateData:any = await this.api.request('template/getOne', {id: "2"});

    document.getElementById('cv').innerHTML = templateData.text;

    (<any>window).angular.module('cvApp', ['dndLists'])
    
    .directive('inlineEdit', ($timeout) => {
      return {
        scope: {
          model: '=inlineEdit',
          handleSave: '&onSave',
          //handleCancel: '&onCancel'
        },
        link: function(scope, elm, attr) {
          var previousValue;
          
          scope.edit = function() {
            scope.editMode = true;
            previousValue = scope.model;
            
            $timeout(function() {
              elm.find('input')[0].focus();
            }, 0, false);
          };
          scope.save = function() {
            scope.editMode = false;
            scope.handleSave({value: scope.model});
          };
          scope.cancel = function() {
            scope.editMode = false;
            scope.model = previousValue;
            //scope.handleCancel({value: scope.model});
          };
        },
        template: `
          <div class='inlineEdit' ng-mouseenter="showEdit = true" ng-mouseleave="showEdit = false">
            <input class='hideInPreview' type="text" on-enter="save()" on-esc="cancel()" ng-model="model" ng-show="editMode">
            <a ng-click="cancel()" ng-show="editMode"><i class="fas fa-times"></i></a>
            <a ng-click="save()" ng-show="editMode"><i class="far fa-save"></i></a>
            <span ng-hide="editMode" ng-click="edit()">{{model}}</span>
            <a ng-show="showEdit && !editMode" ng-click="edit()"><i class="fas fa-edit"></i></a>
          </div>
        `
      };
    })
    
    .controller('cvController', ['$scope', '$sce', ($scope, $sce) => {
      $scope.saveCv = () => {
        //console.log('',$scope.cvData);
        this.updateCvData($scope.cvData);
      };
      $scope.trust = function(text) { return $sce.trustAsHtml(text); }
      $scope.user = this.api.user;
      $scope.userlanguages = this.userlanguages;
      $scope.cvData = this.data.cvData;
      $scope.image = this.data.cvData.image ? $scope.image = this.api.fileUrl+this.data.cvData.image : '';
      console.log('cvController', $scope);


      $scope.models = {
        selected: null,
      };

      $scope.edit = (cv_entry_type, cv_entry) => {
        const dialog = this.api.dialog.open(CvEntryDialogComponent, {
          data: {
            id: cv_entry.id,
            afterSave: (data) => {
              for (var i in cv_entry_type.cv_entry) {
                if (cv_entry_type.cv_entry[i].id !== cv_entry.id) {
                  continue;
                }
                Object.keys(data).map(key => cv_entry_type.cv_entry[i][key] = data[key]);
                dialog.close();
              }
              if (!$scope.$$phase) $scope.$apply();
            }
          },
        });
      }

      $scope.delete = (cv_entry_type, cv_entry) => {
        this.api.confirm(this.api.trns('Remove Cv entry'), this.api.trns('Are you sure?'), () => {
          cv_entry_type.cv_entry = cv_entry_type.cv_entry.filter(e => e.id !== cv_entry.id);
          $scope.saveCv();
          if (!$scope.$$phase) $scope.$apply();
        });
      }

      $scope.addEntry = (cv_entry_type) => {
        const dialog = this.api.dialog.open(CventryAddDialogComponent, {
          data: {
            cv_entry_type,
            afterSave: (data) => {
              console.log('',data);
              cv_entry_type.cv_entry.push(data);
              dialog.close();
              if (!$scope.$$phase) $scope.$apply();
              $scope.saveCv();
            }
          },
        });
      }

      $scope.dragStart = (id) => {
        $scope.currentMoveId = id;
      }

      $scope.movedEntry = (parentIndex, $index) => {
        const id = $scope.currentMoveId;
        const cv_entries = $scope.cvData.data.cv_entry_type[parentIndex].cv_entry;

        $scope.cvData.data.cv_entry_type[parentIndex].cv_entry.splice(cv_entries[$index+1]?.id == id ? ($index+1) : $index, 1);
        
        if (!$scope.$$phase) $scope.$apply();
        $scope.saveCv();
      }

      $scope.documentCount = (cv_entry) => {
        return cv_entry?.f_document_ids ? cv_entry?.f_document_ids?.split(',').length : 0;
      }

      $scope.starIndex = (item) => {
        const slug = this.options_f_language_skill_id.find(e => e.value === item.f_language_skill_id).slug;
    
        const slugAndNum = [
          "language_skill_level_basic",
          "language_skill_level_good",
          "language_skill_level_fluent",
          "language_skill_level_negotiable",
          "language_skill_level_mothertongue",			
        ];
    
        return slugAndNum.indexOf(slug);
      }
    
      $scope.showStar = (index, item) => {
        return $scope.starIndex(item) >= index;
      }
    
      $scope.shortByStar = (items) => {
        return items.sort((a,b) => { 
          const aIndex = $scope.starIndex(a);
          const bIndex = $scope.starIndex(b);
          return aIndex > bIndex ? -1 : aIndex < bIndex ? 1 : 0;
        });
      }

      $scope.getFlag = (item) => {
        return this.api.fileUrl + this.languages.find(l => l.value == item.f_language_id).image;
      }

    }]);

    (<any>window).angular.bootstrap(document.getElementById("cv"), ['cvApp']);
  }
  
}
