import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  HostListener,
  AfterViewInit
} from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/config/config.service';
import { AuthService } from 'src/app/core/service/auth.service';
import { RightSidebarService } from 'src/app/core/service/rightsidebar.service';
import { WINDOW } from 'src/app/core/service/window.service';
import { LanguageService } from 'src/app/core/service/language.service';
import { UnsubscribeOnDestroyAdapter } from 'src/app/shared/UnsubscribeOnDestroyAdapter';
import { ApiService } from 'src/app/api.service';
import { TranslateService } from '@ngx-translate/core';
const document: any = window.document;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit, AfterViewInit
{
  public config: any = {};
  isNavbarCollapsed = true;
  isNavbarShow = true;
  isOpenSidebar: boolean;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private rightSidebarService: RightSidebarService,
    private configService: ConfigService,
    private authService: AuthService,
    private router: Router,
    public languageService: LanguageService,
    public api: ApiService,
    public translate: TranslateService
  ) {
    super();
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const offset =
      this.window.pageYOffset ||
      this.document.documentElement.scrollTop ||
      this.document.body.scrollTop ||
      0;
    // if (offset > 50) {
    //   this.isNavbarShow = true;
    // } else {
    //   this.isNavbarShow = false;
    // }
  }
  async ngOnInit() {

  }

  ngAfterViewInit() {
    // // set theme on startup
    // if (localStorage.getItem('theme')) {
    //   this.renderer.removeClass(this.document.body, this.config.layout.variant);
    //   this.renderer.addClass(this.document.body, localStorage.getItem('theme'));
    // } else {
    //   this.renderer.addClass(this.document.body, this.config.layout.variant);
    // }

    // if (localStorage.getItem('menuOption')) {
    //   this.renderer.addClass(
    //     this.document.body,
    //     localStorage.getItem('menuOption')
    //   );
    // } else {
    //   this.renderer.addClass(
    //     this.document.body,
    //     'menu_' + this.config.layout.sidebar.backgroundColor
    //   );
    // }

    // if (localStorage.getItem('choose_logoheader')) {
    //   this.renderer.addClass(
    //     this.document.body,
    //     localStorage.getItem('choose_logoheader')
    //   );
    // } else {
    //   this.renderer.addClass(
    //     this.document.body,
    //     'logo-' + this.config.layout.logo_bg_color
    //   );
    // }

    // if (localStorage.getItem('sidebar_status')) {
    //   if (localStorage.getItem('sidebar_status') === 'close') {
    //     this.renderer.addClass(this.document.body, 'side-closed');
    //     this.renderer.addClass(this.document.body, 'submenu-closed');
    //   } else {
    //     this.renderer.removeClass(this.document.body, 'side-closed');
    //     this.renderer.removeClass(this.document.body, 'submenu-closed');
    //   }
    // } else {
    //   if (this.config.layout.sidebar.collapsed === true) {
    //     this.renderer.addClass(this.document.body, 'side-closed');
    //     this.renderer.addClass(this.document.body, 'submenu-closed');
    //   }
    // }
  }

  callFullscreen() {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }

  mobileMenuSidebarOpen(event: any, className: string) {
    const hasClass = event.target.classList.contains(className);
    if (hasClass) {
      this.renderer.removeClass(this.document.body, className);
    } else {
      this.renderer.addClass(this.document.body, className);
    }
  }
  callSidemenuCollapse() {
    const hasClass = this.document.body.classList.contains('side-closed');
    if (hasClass) {
      this.renderer.removeClass(this.document.body, 'side-closed');
      this.renderer.removeClass(this.document.body, 'submenu-closed');
    } else {
      this.renderer.addClass(this.document.body, 'side-closed');
      this.renderer.addClass(this.document.body, 'submenu-closed');
    }
  }
  logout() {
    this.api.logout();
    // this.subs.sink = this.authService.logout().subscribe((res) => {
    //   if (!res.success) {
    //     this.router.navigate(['/authentication/signin']);
    //   }
    // });
  }
}
