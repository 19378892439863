<div class="language-selector-main" ngbDropdown>
  <a ngbDropdownToggle class="lang-dropdown">
    <img *ngIf="flagvalue !== undefined && flagvalue" [src]="api.fileUrl+flagvalue" height="16" style="top: -5px;position: relative;"/>
    <img *ngIf="flagvalue === undefined && flagvalue" [src]="api.fileUrl+defaultFlag" height="16" style="top: -5px;position: relative;"/>
  </a>
  <div ngbDropdownMenu class="dropdown-menu lang-item dropdown-menu-right">
    <a href="javascript:void(0);" class="dropdown-item lang-item-list" *ngFor="let item of api.languages"
      (click)="setLanguage(item.text, item.code, item.image)"
      [ngClass]="{'active': langStoreValue === item.code}">
      <img [src]="api.fileUrl+item.image" class="flag-img" height="12" loading="lazy"/> <span class="align-middle">{{item.name}}</span>
    </a>
  </div>
</div>
