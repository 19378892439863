import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { MatChip } from '@angular/material/chips';

@Component({
  selector: 'sm-checkbox-multiple',
  templateUrl: './sm-checkbox-multiple.component.html',
  styleUrls: ['./sm-checkbox-multiple.component.scss']
})
export class SmCheckboxMultipleComponent implements OnInit {

	@Input() label:string;
	@Input() model:any;
	@Input() field:any;
	@Input() error: any = {};
	@Input() options:any;
	@Input() disabled: any = false;
	@Input() showToggle = true;
	@Input() useChips = false;
	@Input() singleSelection = false;
  @Output() change = new EventEmitter();

	constructor() { }

	public showChips = true;

	ngOnChanges(changes: SimpleChanges) {
		if (changes.model||changes.options||changes.label) {
			this.ngOnInit();
		}
	}

  selectAll() {
    this.model[this.field] = this.options.map(o => o.value).join(",");
    this.ngOnInit();
    this.change.emit(this.model[this.field]);
  }

  unselectAll() {
    this.model[this.field] = "";
    this.ngOnInit();
    this.change.emit(this.model[this.field]);
  }

  ngOnInit() {
		if (!this.options) {
			this.options = [];
		}
	}

	update(option, event) {
		let selectedOptions:any = this.model[this.field] ? this.model[this.field].split(",") : [];

		if (event.target.checked) {
			selectedOptions.push(option.value);
		}
		else {
			selectedOptions = selectedOptions.filter(e => e !== option.value);
		}	

		this.model[this.field] = selectedOptions.join(",");
		//this.change.emit(this.model[this.field]);	
	}

	updateChips(option, chip) {
		chip.toggleSelected();

		let selectedOptions:any = this.model[this.field] ? this.model[this.field].split(",") : [];

		if (selectedOptions.indexOf(option.value) === -1) {
			if (this.singleSelection) {
				selectedOptions = [option.value];
			}
			else {
				selectedOptions.push(option.value);
			}
		}
		else {
			if (this.singleSelection) {
				selectedOptions = [];
			}
			else {
				selectedOptions = selectedOptions.filter(e => e !== option.value);
			}
		}	
		this.model[this.field] = selectedOptions.join(",");
		this.change.emit(this.model[this.field]);	
	}

	isChecked(option) {
		let selectedOptions:any = this.model[this.field] ? this.model[this.field].split(",") : [];
		return selectedOptions.indexOf(option.value) !== -1;
	}

	toggleSelection(chip: MatChip) {
		chip.toggleSelected();
 	}

	 chipsChanged($event) {
		 console.log('E',$event);
	 }

}
