import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SmSelectIconComponent } from './sm-select-icon.component';

import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';


@NgModule({
  declarations: [SmSelectIconComponent],
  imports: [
		NgbModule, TranslateModule, FormsModule, MatFormFieldModule, MatInputModule,


    CommonModule,
	],
	exports: [
		SmSelectIconComponent
	]
})
export class SmSelectIconModule { }
