export const icons = [
	{
		"icon": "",
		"search": "none"
	},
	{
		"icon": "address-book",
		"search": "contact, directory, index, little black book, rolodex"
	},
	{
		"icon": "address-card",
		"search": "about, contact, id, identification, postcard, profile"
	},
	{
		"icon": "adjust",
		"search": "contrast, dark, light, saturation"
	},
	{
		"icon": "air-freshener",
		"search": "car, deodorize, fresh, pine, scent"
	},
	{
		"icon": "align-center",
		"search": "format, middle, paragraph, text"
	},
	{
		"icon": "align-justify",
		"search": "format, paragraph, text"
	},
	{
		"icon": "align-left",
		"search": "format, paragraph, text"
	},
	{
		"icon": "align-right",
		"search": "format, paragraph, text"
	},
	{
		"icon": "allergies",
		"search": "allergy, freckles, hand, hives, pox, skin, spots"
	},
	{
		"icon": "ambulance",
		"search": "emergency, emt, er, help, hospital, support, vehicle"
	},
	{
		"icon": "american-sign-language-interpreting",
		"search": "asl, deaf, finger, hand, interpret, speak"
	},
	{
		"icon": "anchor",
		"search": "berth, boat, dock, embed, link, maritime, moor, secure"
	},
	{
		"icon": "angle-double-down",
		"search": "arrows, caret, download, expand"
	},
	{
		"icon": "angle-double-left",
		"search": "arrows, back, caret, laquo, previous, quote"
	},
	{
		"icon": "angle-double-right",
		"search": "arrows, caret, forward, more, next, quote, raquo"
	},
	{
		"icon": "angle-double-up",
		"search": "arrows, caret, collapse, upload"
	},
	{
		"icon": "angle-down",
		"search": "arrow, caret, download, expand"
	},
	{
		"icon": "angle-left",
		"search": "arrow, back, caret, less, previous"
	},
	{
		"icon": "angle-right",
		"search": "arrow, care, forward, more, next"
	},
	{
		"icon": "angle-up",
		"search": "arrow, caret, collapse, upload"
	},
	{
		"icon": "angry",
		"search": "disapprove, emoticon, face, mad, upset"
	},
	{
		"icon": "ankh",
		"search": "amulet, copper, coptic christianity, copts, crux ansata, egypt, venus"
	},
	{
		"icon": "apple-alt",
		"search": "fall, fruit, fuji, macintosh, orchard, seasonal, vegan"
	},
	{
		"icon": "archive",
		"search": "box, package, save, storage"
	},
	{
		"icon": "archway",
		"search": "arc, monument, road, street, tunnel"
	},
	{
		"icon": "arrow-alt-circle-down",
		"search": "arrow-circle-o-down, download"
	},
	{
		"icon": "arrow-alt-circle-left",
		"search": "arrow-circle-o-left, back, previous"
	},
	{
		"icon": "arrow-alt-circle-right",
		"search": "arrow-circle-o-right, forward, next"
	},
	{
		"icon": "arrow-alt-circle-up",
		"search": "arrow-circle-o-up"
	},
	{
		"icon": "arrow-circle-down",
		"search": "download"
	},
	{
		"icon": "arrow-circle-left",
		"search": "back, previous"
	},
	{
		"icon": "arrow-circle-right",
		"search": "forward, next"
	},
	{
		"icon": "arrow-circle-up",
		"search": "upload"
	},
	{
		"icon": "arrow-down",
		"search": "download"
	},
	{
		"icon": "arrow-left",
		"search": "back, previous"
	},
	{
		"icon": "arrow-right",
		"search": "forward, next"
	},
	{
		"icon": "arrow-up",
		"search": "forward, upload"
	},
	{
		"icon": "arrows-alt",
		"search": "arrow, arrows, bigger, enlarge, expand, fullscreen, move, position, reorder, resize"
	},
	{
		"icon": "arrows-alt-h",
		"search": "arrows-h, expand, horizontal, landscape, resize, wide"
	},
	{
		"icon": "arrows-alt-v",
		"search": "arrows-v, expand, portrait, resize, tall, vertical"
	},
	{
		"icon": "assistive-listening-systems",
		"search": "amplify, audio, deaf, ear, headset, hearing, sound"
	},
	{
		"icon": "asterisk",
		"search": "annotation, details, reference, star"
	},
	{
		"icon": "at",
		"search": "address, author, e-mail, email, handle"
	},
	{
		"icon": "atlas",
		"search": "book, directions, geography, globe, map, travel, wayfinding"
	},
	{
		"icon": "atom",
		"search": "atheism, chemistry, electron, ion, isotope, neutron, nuclear, proton, science"
	},
	{
		"icon": "audio-description",
		"search": "blind, narration, video, visual"
	},
	{
		"icon": "award",
		"search": "honor, praise, prize, recognition, ribbon, trophy"
	},
	{
		"icon": "baby",
		"search": "child, diaper, doll, human, infant, kid, offspring, person, sprout"
	},
	{
		"icon": "baby-carriage",
		"search": "buggy, carrier, infant, push, stroller, transportation, walk, wheels"
	},
	{
		"icon": "backspace",
		"search": "command, delete, erase, keyboard, undo"
	},
	{
		"icon": "backward",
		"search": "previous, rewind"
	},
	{
		"icon": "bacon",
		"search": "blt, breakfast, ham, lard, meat, pancetta, pork, rasher"
	},
	{
		"icon": "bahai",
		"search": "bahai, bahá'í, star"
	},
	{
		"icon": "balance-scale",
		"search": "balanced, justice, legal, measure, weight"
	},
	{
		"icon": "balance-scale-left",
		"search": "justice, legal, measure, unbalanced, weight"
	},
	{
		"icon": "balance-scale-right",
		"search": "justice, legal, measure, unbalanced, weight"
	},
	{
		"icon": "ban",
		"search": "abort, ban, block, cancel, delete, hide, prohibit, remove, stop, trash"
	},
	{
		"icon": "band-aid",
		"search": "bandage, boo boo, first aid, ouch"
	},
	{
		"icon": "barcode",
		"search": "info, laser, price, scan, upc"
	},
	{
		"icon": "bars",
		"search": "checklist, drag, hamburger, list, menu, nav, navigation, ol, reorder, settings, todo, ul"
	},
	{
		"icon": "baseball-ball",
		"search": "foul, hardball, league, leather, mlb, softball, sport"
	},
	{
		"icon": "basketball-ball",
		"search": "dribble, dunk, hoop, nba"
	},
	{
		"icon": "bath",
		"search": "clean, shower, tub, wash"
	},
	{
		"icon": "battery-empty",
		"search": "charge, dead, power, status"
	},
	{
		"icon": "battery-full",
		"search": "charge, power, status"
	},
	{
		"icon": "battery-half",
		"search": "charge, power, status"
	},
	{
		"icon": "battery-quarter",
		"search": "charge, low, power, status"
	},
	{
		"icon": "battery-three-quarters",
		"search": "charge, power, status"
	},
	{
		"icon": "bed",
		"search": "lodging, mattress, rest, sleep, travel"
	},
	{
		"icon": "beer",
		"search": "alcohol, ale, bar, beverage, brewery, drink, lager, liquor, mug, stein"
	},
	{
		"icon": "bell",
		"search": "alarm, alert, chime, notification, reminder"
	},
	{
		"icon": "bell-slash",
		"search": "alert, cancel, disabled, notification, off, reminder"
	},
	{
		"icon": "bezier-curve",
		"search": "curves, illustrator, lines, path, vector"
	},
	{
		"icon": "bible",
		"search": "book, catholicism, christianity, god, holy"
	},
	{
		"icon": "bicycle",
		"search": "bike, gears, pedal, transportation, vehicle"
	},
	{
		"icon": "biking",
		"search": "bicycle, bike, cycle, cycling, ride, wheel"
	},
	{
		"icon": "binoculars",
		"search": "glasses, magnify, scenic, spyglass, view"
	},
	{
		"icon": "biohazard",
		"search": "danger, dangerous, hazmat, medical, radioactive, toxic, waste, zombie"
	},
	{
		"icon": "birthday-cake",
		"search": "anniversary, bakery, candles, celebration, dessert, frosting, holiday, party, pastry"
	},
	{
		"icon": "blender",
		"search": "cocktail, milkshake, mixer, puree, smoothie"
	},
	{
		"icon": "blender-phone",
		"search": "appliance, cocktail, communication, fantasy, milkshake, mixer, puree, silly, smoothie"
	},
	{
		"icon": "blind",
		"search": "cane, disability, person, sight"
	},
	{
		"icon": "blog",
		"search": "journal, log, online, personal, post, web 2.0, wordpress, writing"
	},
	{
		"icon": "bold",
		"search": "emphasis, format, text"
	},
	{
		"icon": "bolt",
		"search": "electricity, lightning, weather, zap"
	},
	{
		"icon": "bomb",
		"search": "error, explode, fuse, grenade, warning"
	},
	{
		"icon": "bone",
		"search": "calcium, dog, skeletal, skeleton, tibia"
	},
	{
		"icon": "bong",
		"search": "aparatus, cannabis, marijuana, pipe, smoke, smoking"
	},
	{
		"icon": "book",
		"search": "diary, documentation, journal, library, read"
	},
	{
		"icon": "book-dead",
		"search": "Dungeons & Dragons, crossbones, d&d, dark arts, death, dnd, documentation, evil, fantasy, halloween, holiday, necronomicon, read, skull, spell"
	},
	{
		"icon": "book-medical",
		"search": "diary, documentation, health, history, journal, library, read, record"
	},
	{
		"icon": "book-open",
		"search": "flyer, library, notebook, open book, pamphlet, reading"
	},
	{
		"icon": "book-reader",
		"search": "flyer, library, notebook, open book, pamphlet, reading"
	},
	{
		"icon": "bookmark",
		"search": "favorite, marker, read, remember, save"
	},
	{
		"icon": "border-all",
		"search": "cell, grid, outline, stroke, table"
	},
	{
		"icon": "border-none",
		"search": "cell, grid, outline, stroke, table"
	},
	{
		"icon": "border-style",
		"search": ""
	},
	{
		"icon": "bowling-ball",
		"search": "alley, candlepin, gutter, lane, strike, tenpin"
	},
	{
		"icon": "box",
		"search": "archive, container, package, storage"
	},
	{
		"icon": "box-open",
		"search": "archive, container, package, storage, unpack"
	},
	{
		"icon": "boxes",
		"search": "archives, inventory, storage, warehouse"
	},
	{
		"icon": "braille",
		"search": "alphabet, blind, dots, raised, vision"
	},
	{
		"icon": "brain",
		"search": "cerebellum, gray matter, intellect, medulla oblongata, mind, noodle, wit"
	},
	{
		"icon": "bread-slice",
		"search": "bake, bakery, baking, dough, flour, gluten, grain, sandwich, sourdough, toast, wheat, yeast"
	},
	{
		"icon": "briefcase",
		"search": "bag, business, luggage, office, work"
	},
	{
		"icon": "briefcase-medical",
		"search": "doctor, emt, first aid, health"
	},
	{
		"icon": "broadcast-tower",
		"search": "airwaves, antenna, radio, reception, waves"
	},
	{
		"icon": "broom",
		"search": "clean, firebolt, fly, halloween, nimbus 2000, quidditch, sweep, witch"
	},
	{
		"icon": "brush",
		"search": "art, bristles, color, handle, paint"
	},
	{
		"icon": "bug",
		"search": "beetle, error, insect, report"
	},
	{
		"icon": "building",
		"search": "apartment, business, city, company, office, work"
	},
	{
		"icon": "bullhorn",
		"search": "announcement, broadcast, louder, megaphone, share"
	},
	{
		"icon": "bullseye",
		"search": "archery, goal, objective, target"
	},
	{
		"icon": "burn",
		"search": "caliente, energy, fire, flame, gas, heat, hot"
	},
	{
		"icon": "bus",
		"search": "public transportation, transportation, travel, vehicle"
	},
	{
		"icon": "bus-alt",
		"search": "mta, public transportation, transportation, travel, vehicle"
	},
	{
		"icon": "business-time",
		"search": "alarm, briefcase, business socks, clock, flight of the conchords, reminder, wednesday"
	},
	{
		"icon": "calculator",
		"search": "abacus, addition, arithmetic, counting, math, multiplication, subtraction"
	},
	{
		"icon": "calendar",
		"search": "calendar-o, date, event, schedule, time, when"
	},
	{
		"icon": "calendar-alt",
		"search": "calendar, date, event, schedule, time, when"
	},
	{
		"icon": "calendar-check",
		"search": "accept, agree, appointment, confirm, correct, date, done, event, ok, schedule, select, success, tick, time, todo, when"
	},
	{
		"icon": "calendar-day",
		"search": "date, detail, event, focus, schedule, single day, time, today, when"
	},
	{
		"icon": "calendar-minus",
		"search": "calendar, date, delete, event, negative, remove, schedule, time, when"
	},
	{
		"icon": "calendar-plus",
		"search": "add, calendar, create, date, event, new, positive, schedule, time, when"
	},
	{
		"icon": "calendar-times",
		"search": "archive, calendar, date, delete, event, remove, schedule, time, when, x"
	},
	{
		"icon": "calendar-week",
		"search": "date, detail, event, focus, schedule, single week, time, today, when"
	},
	{
		"icon": "camera",
		"search": "image, lens, photo, picture, record, shutter, video"
	},
	{
		"icon": "camera-retro",
		"search": "image, lens, photo, picture, record, shutter, video"
	},
	{
		"icon": "campground",
		"search": "camping, fall, outdoors, teepee, tent, tipi"
	},
	{
		"icon": "candy-cane",
		"search": "candy, christmas, holiday, mint, peppermint, striped, xmas"
	},
	{
		"icon": "cannabis",
		"search": "bud, chronic, drugs, endica, endo, ganja, marijuana, mary jane, pot, reefer, sativa, spliff, weed, whacky-tabacky"
	},
	{
		"icon": "capsules",
		"search": "drugs, medicine, pills, prescription"
	},
	{
		"icon": "car",
		"search": "auto, automobile, sedan, transportation, travel, vehicle"
	},
	{
		"icon": "car-alt",
		"search": "auto, automobile, sedan, transportation, travel, vehicle"
	},
	{
		"icon": "car-battery",
		"search": "auto, electric, mechanic, power"
	},
	{
		"icon": "car-crash",
		"search": "accident, auto, automobile, insurance, sedan, transportation, vehicle, wreck"
	},
	{
		"icon": "car-side",
		"search": "auto, automobile, sedan, transportation, travel, vehicle"
	},
	{
		"icon": "caravan",
		"search": "camper, motor home, rv, trailer, travel"
	},
	{
		"icon": "caret-down",
		"search": "arrow, dropdown, expand, menu, more, triangle"
	},
	{
		"icon": "caret-left",
		"search": "arrow, back, previous, triangle"
	},
	{
		"icon": "caret-right",
		"search": "arrow, forward, next, triangle"
	},
	{
		"icon": "caret-square-down",
		"search": "arrow, caret-square-o-down, dropdown, expand, menu, more, triangle"
	},
	{
		"icon": "caret-square-left",
		"search": "arrow, back, caret-square-o-left, previous, triangle"
	},
	{
		"icon": "caret-square-right",
		"search": "arrow, caret-square-o-right, forward, next, triangle"
	},
	{
		"icon": "caret-square-up",
		"search": "arrow, caret-square-o-up, collapse, triangle, upload"
	},
	{
		"icon": "caret-up",
		"search": "arrow, collapse, triangle"
	},
	{
		"icon": "carrot",
		"search": "bugs bunny, orange, vegan, vegetable"
	},
	{
		"icon": "cart-arrow-down",
		"search": "download, save, shopping"
	},
	{
		"icon": "cart-plus",
		"search": "add, create, new, positive, shopping"
	},
	{
		"icon": "cash-register",
		"search": "buy, cha-ching, change, checkout, commerce, leaerboard, machine, pay, payment, purchase, store"
	},
	{
		"icon": "cat",
		"search": "feline, halloween, holiday, kitten, kitty, meow, pet"
	},
	{
		"icon": "certificate",
		"search": "badge, star, verified"
	},
	{
		"icon": "chair",
		"search": "furniture, seat, sit"
	},
	{
		"icon": "chalkboard",
		"search": "blackboard, learning, school, teaching, whiteboard, writing"
	},
	{
		"icon": "chalkboard-teacher",
		"search": "blackboard, instructor, learning, professor, school, whiteboard, writing"
	},
	{
		"icon": "charging-station",
		"search": "electric, ev, tesla, vehicle"
	},
	{
		"icon": "chart-area",
		"search": "analytics, area, chart, graph"
	},
	{
		"icon": "chart-bar",
		"search": "analytics, bar, chart, graph"
	},
	{
		"icon": "chart-line",
		"search": "activity, analytics, chart, dashboard, gain, graph, increase, line"
	},
	{
		"icon": "chart-pie",
		"search": "analytics, chart, diagram, graph, pie"
	},
	{
		"icon": "check",
		"search": "accept, agree, checkmark, confirm, correct, done, notice, notification, notify, ok, select, success, tick, todo, yes"
	},
	{
		"icon": "check-circle",
		"search": "accept, agree, confirm, correct, done, ok, select, success, tick, todo, yes"
	},
	{
		"icon": "check-double",
		"search": "accept, agree, checkmark, confirm, correct, done, notice, notification, notify, ok, select, success, tick, todo"
	},
	{
		"icon": "check-square",
		"search": "accept, agree, checkmark, confirm, correct, done, ok, select, success, tick, todo, yes"
	},
	{
		"icon": "cheese",
		"search": "cheddar, curd, gouda, melt, parmesan, sandwich, swiss, wedge"
	},
	{
		"icon": "chess",
		"search": "board, castle, checkmate, game, king, rook, strategy, tournament"
	},
	{
		"icon": "chess-bishop",
		"search": "board, checkmate, game, strategy"
	},
	{
		"icon": "chess-board",
		"search": "board, checkmate, game, strategy"
	},
	{
		"icon": "chess-king",
		"search": "board, checkmate, game, strategy"
	},
	{
		"icon": "chess-knight",
		"search": "board, checkmate, game, horse, strategy"
	},
	{
		"icon": "chess-pawn",
		"search": "board, checkmate, game, strategy"
	},
	{
		"icon": "chess-queen",
		"search": "board, checkmate, game, strategy"
	},
	{
		"icon": "chess-rook",
		"search": "board, castle, checkmate, game, strategy"
	},
	{
		"icon": "chevron-circle-down",
		"search": "arrow, download, dropdown, menu, more"
	},
	{
		"icon": "chevron-circle-left",
		"search": "arrow, back, previous"
	},
	{
		"icon": "chevron-circle-right",
		"search": "arrow, forward, next"
	},
	{
		"icon": "chevron-circle-up",
		"search": "arrow, collapse, upload"
	},
	{
		"icon": "chevron-down",
		"search": "arrow, download, expand"
	},
	{
		"icon": "chevron-left",
		"search": "arrow, back, bracket, previous"
	},
	{
		"icon": "chevron-right",
		"search": "arrow, bracket, forward, next"
	},
	{
		"icon": "chevron-up",
		"search": "arrow, collapse, upload"
	},
	{
		"icon": "child",
		"search": "boy, girl, kid, toddler, young"
	},
	{
		"icon": "church",
		"search": "building, cathedral, chapel, community, religion"
	},
	{
		"icon": "circle",
		"search": "circle-thin, diameter, dot, ellipse, notification, round"
	},
	{
		"icon": "circle-notch",
		"search": "circle-o-notch, diameter, dot, ellipse, round, spinner"
	},
	{
		"icon": "city",
		"search": "buildings, busy, skyscrapers, urban, windows"
	},
	{
		"icon": "clinic-medical",
		"search": "doctor, general practitioner, hospital, infirmary, medicine, office, outpatient"
	},
	{
		"icon": "clipboard",
		"search": "copy, notes, paste, record"
	},
	{
		"icon": "clipboard-check",
		"search": "accept, agree, confirm, done, ok, select, success, tick, todo, yes"
	},
	{
		"icon": "clipboard-list",
		"search": "checklist, completed, done, finished, intinerary, ol, schedule, tick, todo, ul"
	},
	{
		"icon": "clock",
		"search": "date, late, schedule, time, timer, timestamp, watch"
	},
	{
		"icon": "clone",
		"search": "arrange, copy, duplicate, paste"
	},
	{
		"icon": "closed-captioning",
		"search": "cc, deaf, hearing, subtitle, subtitling, text, video"
	},
	{
		"icon": "cloud",
		"search": "atmosphere, fog, overcast, save, upload, weather"
	},
	{
		"icon": "cloud-download-alt",
		"search": "download, export, save"
	},
	{
		"icon": "cloud-meatball",
		"search": "FLDSMDFR, food, spaghetti, storm"
	},
	{
		"icon": "cloud-moon",
		"search": "crescent, evening, lunar, night, partly cloudy, sky"
	},
	{
		"icon": "cloud-moon-rain",
		"search": "crescent, evening, lunar, night, partly cloudy, precipitation, rain, sky, storm"
	},
	{
		"icon": "cloud-rain",
		"search": "precipitation, rain, sky, storm"
	},
	{
		"icon": "cloud-showers-heavy",
		"search": "precipitation, rain, sky, storm"
	},
	{
		"icon": "cloud-sun",
		"search": "clear, day, daytime, fall, outdoors, overcast, partly cloudy"
	},
	{
		"icon": "cloud-sun-rain",
		"search": "day, overcast, precipitation, storm, summer, sunshower"
	},
	{
		"icon": "cloud-upload-alt",
		"search": "cloud-upload, import, save, upload"
	},
	{
		"icon": "cocktail",
		"search": "alcohol, beverage, drink, gin, glass, margarita, martini, vodka"
	},
	{
		"icon": "code",
		"search": "brackets, code, development, html"
	},
	{
		"icon": "code-branch",
		"search": "branch, code-fork, fork, git, github, rebase, svn, vcs, version"
	},
	{
		"icon": "coffee",
		"search": "beverage, breakfast, cafe, drink, fall, morning, mug, seasonal, tea"
	},
	{
		"icon": "cog",
		"search": "gear, mechanical, settings, sprocket, wheel"
	},
	{
		"icon": "cogs",
		"search": "gears, mechanical, settings, sprocket, wheel"
	},
	{
		"icon": "coins",
		"search": "currency, dime, financial, gold, money, penny"
	},
	{
		"icon": "columns",
		"search": "browser, dashboard, organize, panes, split"
	},
	{
		"icon": "comment",
		"search": "bubble, chat, commenting, conversation, feedback, message, note, notification, sms, speech, texting"
	},
	{
		"icon": "comment-alt",
		"search": "bubble, chat, commenting, conversation, feedback, message, note, notification, sms, speech, texting"
	},
	{
		"icon": "comment-dollar",
		"search": "bubble, chat, commenting, conversation, feedback, message, money, note, notification, pay, sms, speech, spend, texting, transfer"
	},
	{
		"icon": "comment-dots",
		"search": "bubble, chat, commenting, conversation, feedback, message, more, note, notification, reply, sms, speech, texting"
	},
	{
		"icon": "comment-medical",
		"search": "advice, bubble, chat, commenting, conversation, diagnose, feedback, message, note, notification, prescription, sms, speech, texting"
	},
	{
		"icon": "comment-slash",
		"search": "bubble, cancel, chat, commenting, conversation, feedback, message, mute, note, notification, quiet, sms, speech, texting"
	},
	{
		"icon": "comments",
		"search": "bubble, chat, commenting, conversation, feedback, message, note, notification, sms, speech, texting"
	},
	{
		"icon": "comments-dollar",
		"search": "bubble, chat, commenting, conversation, feedback, message, money, note, notification, pay, sms, speech, spend, texting, transfer"
	},
	{
		"icon": "compact-disc",
		"search": "album, bluray, cd, disc, dvd, media, movie, music, record, video, vinyl"
	},
	{
		"icon": "compass",
		"search": "directions, directory, location, menu, navigation, safari, travel"
	},
	{
		"icon": "compress",
		"search": "collapse, fullscreen, minimize, move, resize, shrink, smaller"
	},
	{
		"icon": "compress-alt",
		"search": "collapse, fullscreen, minimize, move, resize, shrink, smaller"
	},
	{
		"icon": "compress-arrows-alt",
		"search": "collapse, fullscreen, minimize, move, resize, shrink, smaller"
	},
	{
		"icon": "concierge-bell",
		"search": "attention, hotel, receptionist, service, support"
	},
	{
		"icon": "cookie",
		"search": "baked good, chips, chocolate, eat, snack, sweet, treat"
	},
	{
		"icon": "cookie-bite",
		"search": "baked good, bitten, chips, chocolate, eat, snack, sweet, treat"
	},
	{
		"icon": "copy",
		"search": "clone, duplicate, file, files-o, paper, paste"
	},
	{
		"icon": "copyright",
		"search": "brand, mark, register, trademark"
	},
	{
		"icon": "couch",
		"search": "chair, cushion, furniture, relax, sofa"
	},
	{
		"icon": "credit-card",
		"search": "buy, checkout, credit-card-alt, debit, money, payment, purchase"
	},
	{
		"icon": "crop",
		"search": "design, frame, mask, resize, shrink"
	},
	{
		"icon": "crop-alt",
		"search": "design, frame, mask, resize, shrink"
	},
	{
		"icon": "cross",
		"search": "catholicism, christianity, church, jesus"
	},
	{
		"icon": "crosshairs",
		"search": "aim, bullseye, gpd, picker, position"
	},
	{
		"icon": "crow",
		"search": "bird, bullfrog, fauna, halloween, holiday, toad"
	},
	{
		"icon": "crown",
		"search": "award, favorite, king, queen, royal, tiara"
	},
	{
		"icon": "crutch",
		"search": "cane, injury, mobility, wheelchair"
	},
	{
		"icon": "cube",
		"search": "3d, block, dice, package, square, tesseract"
	},
	{
		"icon": "cubes",
		"search": "3d, block, dice, package, pyramid, square, stack, tesseract"
	},
	{
		"icon": "cut",
		"search": "clip, scissors, snip"
	},
	{
		"icon": "database",
		"search": "computer, development, directory, memory, storage"
	},
	{
		"icon": "deaf",
		"search": "ear, hearing, sign language"
	},
	{
		"icon": "democrat",
		"search": "american, democratic party, donkey, election, left, left-wing, liberal, politics, usa"
	},
	{
		"icon": "desktop",
		"search": "computer, cpu, demo, desktop, device, imac, machine, monitor, pc, screen"
	},
	{
		"icon": "dharmachakra",
		"search": "buddhism, buddhist, wheel of dharma"
	},
	{
		"icon": "diagnoses",
		"search": "analyze, detect, diagnosis, examine, medicine"
	},
	{
		"icon": "dice",
		"search": "chance, gambling, game, roll"
	},
	{
		"icon": "dice-d20",
		"search": "Dungeons & Dragons, chance, d&d, dnd, fantasy, gambling, game, roll"
	},
	{
		"icon": "dice-d6",
		"search": "Dungeons & Dragons, chance, d&d, dnd, fantasy, gambling, game, roll"
	},
	{
		"icon": "dice-five",
		"search": "chance, gambling, game, roll"
	},
	{
		"icon": "dice-four",
		"search": "chance, gambling, game, roll"
	},
	{
		"icon": "dice-one",
		"search": "chance, gambling, game, roll"
	},
	{
		"icon": "dice-six",
		"search": "chance, gambling, game, roll"
	},
	{
		"icon": "dice-three",
		"search": "chance, gambling, game, roll"
	},
	{
		"icon": "dice-two",
		"search": "chance, gambling, game, roll"
	},
	{
		"icon": "digital-tachograph",
		"search": "data, distance, speed, tachometer"
	},
	{
		"icon": "directions",
		"search": "map, navigation, sign, turn"
	},
	{
		"icon": "divide",
		"search": "arithmetic, calculus, division, math"
	},
	{
		"icon": "dizzy",
		"search": "dazed, dead, disapprove, emoticon, face"
	},
	{
		"icon": "dna",
		"search": "double helix, genetic, helix, molecule, protein"
	},
	{
		"icon": "dog",
		"search": "animal, canine, fauna, mammal, pet, pooch, puppy, woof"
	},
	{
		"icon": "dollar-sign",
		"search": "$, cost, dollar-sign, money, price, usd"
	},
	{
		"icon": "dolly",
		"search": "carry, shipping, transport"
	},
	{
		"icon": "dolly-flatbed",
		"search": "carry, inventory, shipping, transport"
	},
	{
		"icon": "donate",
		"search": "contribute, generosity, gift, give"
	},
	{
		"icon": "door-closed",
		"search": "enter, exit, locked"
	},
	{
		"icon": "door-open",
		"search": "enter, exit, welcome"
	},
	{
		"icon": "dot-circle",
		"search": "bullseye, notification, target"
	},
	{
		"icon": "dove",
		"search": "bird, fauna, flying, peace, war"
	},
	{
		"icon": "download",
		"search": "export, hard drive, save, transfer"
	},
	{
		"icon": "drafting-compass",
		"search": "design, map, mechanical drawing, plot, plotting"
	},
	{
		"icon": "dragon",
		"search": "Dungeons & Dragons, d&d, dnd, fantasy, fire, lizard, serpent"
	},
	{
		"icon": "draw-polygon",
		"search": "anchors, lines, object, render, shape"
	},
	{
		"icon": "drum",
		"search": "instrument, music, percussion, snare, sound"
	},
	{
		"icon": "drum-steelpan",
		"search": "calypso, instrument, music, percussion, reggae, snare, sound, steel, tropical"
	},
	{
		"icon": "drumstick-bite",
		"search": "bone, chicken, leg, meat, poultry, turkey"
	},
	{
		"icon": "dumbbell",
		"search": "exercise, gym, strength, weight, weight-lifting"
	},
	{
		"icon": "dumpster",
		"search": "alley, bin, commercial, trash, waste"
	},
	{
		"icon": "dumpster-fire",
		"search": "alley, bin, commercial, danger, dangerous, euphemism, flame, heat, hot, trash, waste"
	},
	{
		"icon": "dungeon",
		"search": "Dungeons & Dragons, building, d&d, dnd, door, entrance, fantasy, gate"
	},
	{
		"icon": "edit",
		"search": "edit, pen, pencil, update, write"
	},
	{
		"icon": "egg",
		"search": "breakfast, chicken, easter, shell, yolk"
	},
	{
		"icon": "eject",
		"search": "abort, cancel, cd, discharge"
	},
	{
		"icon": "ellipsis-h",
		"search": "dots, drag, kebab, list, menu, nav, navigation, ol, reorder, settings, ul"
	},
	{
		"icon": "ellipsis-v",
		"search": "dots, drag, kebab, list, menu, nav, navigation, ol, reorder, settings, ul"
	},
	{
		"icon": "envelope",
		"search": "e-mail, email, letter, mail, message, notification, support"
	},
	{
		"icon": "envelope-open",
		"search": "e-mail, email, letter, mail, message, notification, support"
	},
	{
		"icon": "envelope-open-text",
		"search": "e-mail, email, letter, mail, message, notification, support"
	},
	{
		"icon": "envelope-square",
		"search": "e-mail, email, letter, mail, message, notification, support"
	},
	{
		"icon": "equals",
		"search": "arithmetic, even, match, math"
	},
	{
		"icon": "eraser",
		"search": "art, delete, remove, rubber"
	},
	{
		"icon": "ethernet",
		"search": "cable, cat 5, cat 6, connection, hardware, internet, network, wired"
	},
	{
		"icon": "euro-sign",
		"search": "currency, dollar, exchange, money"
	},
	{
		"icon": "exchange-alt",
		"search": "arrow, arrows, exchange, reciprocate, return, swap, transfer"
	},
	{
		"icon": "exclamation",
		"search": "alert, danger, error, important, notice, notification, notify, problem, warning"
	},
	{
		"icon": "exclamation-circle",
		"search": "alert, danger, error, important, notice, notification, notify, problem, warning"
	},
	{
		"icon": "exclamation-triangle",
		"search": "alert, danger, error, important, notice, notification, notify, problem, warning"
	},
	{
		"icon": "expand",
		"search": "arrow, bigger, enlarge, resize"
	},
	{
		"icon": "expand-alt",
		"search": "arrow, bigger, enlarge, resize"
	},
	{
		"icon": "expand-arrows-alt",
		"search": "arrows-alt, bigger, enlarge, move, resize"
	},
	{
		"icon": "external-link-alt",
		"search": "external-link, new, open, share"
	},
	{
		"icon": "external-link-square-alt",
		"search": "external-link-square, new, open, share"
	},
	{
		"icon": "eye",
		"search": "look, optic, see, seen, show, sight, views, visible"
	},
	{
		"icon": "eye-dropper",
		"search": "beaker, clone, color, copy, eyedropper, pipette"
	},
	{
		"icon": "eye-slash",
		"search": "blind, hide, show, toggle, unseen, views, visible, visiblity"
	},
	{
		"icon": "fan",
		"search": "ac, air conditioning, blade, blower, cool, hot"
	},
	{
		"icon": "fast-backward",
		"search": "beginning, first, previous, rewind, start"
	},
	{
		"icon": "fast-forward",
		"search": "end, last, next"
	},
	{
		"icon": "fax",
		"search": "business, communicate, copy, facsimile, send"
	},
	{
		"icon": "feather",
		"search": "bird, light, plucked, quill, write"
	},
	{
		"icon": "feather-alt",
		"search": "bird, light, plucked, quill, write"
	},
	{
		"icon": "female",
		"search": "human, person, profile, user, woman"
	},
	{
		"icon": "fighter-jet",
		"search": "airplane, fast, fly, goose, maverick, plane, quick, top gun, transportation, travel"
	},
	{
		"icon": "file",
		"search": "document, new, page, pdf, resume"
	},
	{
		"icon": "file-alt",
		"search": "document, file-text, invoice, new, page, pdf"
	},
	{
		"icon": "file-archive",
		"search": ".zip, bundle, compress, compression, download, zip"
	},
	{
		"icon": "file-audio",
		"search": "document, mp3, music, page, play, sound"
	},
	{
		"icon": "file-code",
		"search": "css, development, document, html"
	},
	{
		"icon": "file-contract",
		"search": "agreement, binding, document, legal, signature"
	},
	{
		"icon": "file-csv",
		"search": "document, excel, numbers, spreadsheets, table"
	},
	{
		"icon": "file-download",
		"search": "document, export, save"
	},
	{
		"icon": "file-excel",
		"search": "csv, document, numbers, spreadsheets, table"
	},
	{
		"icon": "file-export",
		"search": "download, save"
	},
	{
		"icon": "file-image",
		"search": "document, image, jpg, photo, png"
	},
	{
		"icon": "file-import",
		"search": "copy, document, send, upload"
	},
	{
		"icon": "file-invoice",
		"search": "account, bill, charge, document, payment, receipt"
	},
	{
		"icon": "file-invoice-dollar",
		"search": "$, account, bill, charge, document, dollar-sign, money, payment, receipt, usd"
	},
	{
		"icon": "file-medical",
		"search": "document, health, history, prescription, record"
	},
	{
		"icon": "file-medical-alt",
		"search": "document, health, history, prescription, record"
	},
	{
		"icon": "file-pdf",
		"search": "acrobat, document, preview, save"
	},
	{
		"icon": "file-powerpoint",
		"search": "display, document, keynote, presentation"
	},
	{
		"icon": "file-prescription",
		"search": "document, drugs, medical, medicine, rx"
	},
	{
		"icon": "file-signature",
		"search": "John Hancock, contract, document, name"
	},
	{
		"icon": "file-upload",
		"search": "document, import, page, save"
	},
	{
		"icon": "file-video",
		"search": "document, m4v, movie, mp4, play"
	},
	{
		"icon": "file-word",
		"search": "document, edit, page, text, writing"
	},
	{
		"icon": "fill",
		"search": "bucket, color, paint, paint bucket"
	},
	{
		"icon": "fill-drip",
		"search": "bucket, color, drop, paint, paint bucket, spill"
	},
	{
		"icon": "film",
		"search": "cinema, movie, strip, video"
	},
	{
		"icon": "filter",
		"search": "funnel, options, separate, sort"
	},
	{
		"icon": "fingerprint",
		"search": "human, id, identification, lock, smudge, touch, unique, unlock"
	},
	{
		"icon": "fire",
		"search": "burn, caliente, flame, heat, hot, popular"
	},
	{
		"icon": "fire-alt",
		"search": "burn, caliente, flame, heat, hot, popular"
	},
	{
		"icon": "fire-extinguisher",
		"search": "burn, caliente, fire fighter, flame, heat, hot, rescue"
	},
	{
		"icon": "first-aid",
		"search": "emergency, emt, health, medical, rescue"
	},
	{
		"icon": "fish",
		"search": "fauna, gold, seafood, swimming"
	},
	{
		"icon": "fist-raised",
		"search": "Dungeons & Dragons, d&d, dnd, fantasy, hand, ki, monk, resist, strength, unarmed combat"
	},
	{
		"icon": "flag",
		"search": "country, notice, notification, notify, pole, report, symbol"
	},
	{
		"icon": "flag-checkered",
		"search": "notice, notification, notify, pole, racing, report, symbol"
	},
	{
		"icon": "flag-usa",
		"search": "betsy ross, country, old glory, stars, stripes, symbol"
	},
	{
		"icon": "flask",
		"search": "beaker, experimental, labs, science"
	},
	{
		"icon": "flushed",
		"search": "embarrassed, emoticon, face"
	},
	{
		"icon": "folder",
		"search": "archive, directory, document, file"
	},
	{
		"icon": "folder-minus",
		"search": "archive, delete, directory, document, file, negative, remove"
	},
	{
		"icon": "folder-open",
		"search": "archive, directory, document, empty, file, new"
	},
	{
		"icon": "folder-plus",
		"search": "add, archive, create, directory, document, file, new, positive"
	},
	{
		"icon": "font",
		"search": "alphabet, glyph, text, type, typeface"
	},
	{
		"icon": "football-ball",
		"search": "ball, fall, nfl, pigskin, seasonal"
	},
	{
		"icon": "forward",
		"search": "forward, next, skip"
	},
	{
		"icon": "frog",
		"search": "amphibian, bullfrog, fauna, hop, kermit, kiss, prince, ribbit, toad, wart"
	},
	{
		"icon": "frown",
		"search": "disapprove, emoticon, face, rating, sad"
	},
	{
		"icon": "frown-open",
		"search": "disapprove, emoticon, face, rating, sad"
	},
	{
		"icon": "funnel-dollar",
		"search": "filter, money, options, separate, sort"
	},
	{
		"icon": "futbol",
		"search": "ball, football, mls, soccer"
	},
	{
		"icon": "gamepad",
		"search": "arcade, controller, d-pad, joystick, video, video game"
	},
	{
		"icon": "gas-pump",
		"search": "car, fuel, gasoline, petrol"
	},
	{
		"icon": "gavel",
		"search": "hammer, judge, law, lawyer, opinion"
	},
	{
		"icon": "gem",
		"search": "diamond, jewelry, sapphire, stone, treasure"
	},
	{
		"icon": "genderless",
		"search": "androgynous, asexual, sexless"
	},
	{
		"icon": "ghost",
		"search": "apparition, blinky, clyde, floating, halloween, holiday, inky, pinky, spirit"
	},
	{
		"icon": "gift",
		"search": "christmas, generosity, giving, holiday, party, present, wrapped, xmas"
	},
	{
		"icon": "gifts",
		"search": "christmas, generosity, giving, holiday, party, present, wrapped, xmas"
	},
	{
		"icon": "glass-cheers",
		"search": "alcohol, bar, beverage, celebration, champagne, clink, drink, holiday, new year's eve, party, toast"
	},
	{
		"icon": "glass-martini",
		"search": "alcohol, bar, beverage, drink, liquor"
	},
	{
		"icon": "glass-martini-alt",
		"search": "alcohol, bar, beverage, drink, liquor"
	},
	{
		"icon": "glass-whiskey",
		"search": "alcohol, bar, beverage, bourbon, drink, liquor, neat, rye, scotch, whisky"
	},
	{
		"icon": "glasses",
		"search": "hipster, nerd, reading, sight, spectacles, vision"
	},
	{
		"icon": "globe",
		"search": "all, coordinates, country, earth, global, gps, language, localize, location, map, online, place, planet, translate, travel, world"
	},
	{
		"icon": "globe-africa",
		"search": "all, country, earth, global, gps, language, localize, location, map, online, place, planet, translate, travel, world"
	},
	{
		"icon": "globe-americas",
		"search": "all, country, earth, global, gps, language, localize, location, map, online, place, planet, translate, travel, world"
	},
	{
		"icon": "globe-asia",
		"search": "all, country, earth, global, gps, language, localize, location, map, online, place, planet, translate, travel, world"
	},
	{
		"icon": "globe-europe",
		"search": "all, country, earth, global, gps, language, localize, location, map, online, place, planet, translate, travel, world"
	},
	{
		"icon": "golf-ball",
		"search": "caddy, eagle, putt, tee"
	},
	{
		"icon": "gopuram",
		"search": "building, entrance, hinduism, temple, tower"
	},
	{
		"icon": "graduation-cap",
		"search": "ceremony, college, graduate, learning, school, student"
	},
	{
		"icon": "greater-than",
		"search": "arithmetic, compare, math"
	},
	{
		"icon": "greater-than-equal",
		"search": "arithmetic, compare, math"
	},
	{
		"icon": "grimace",
		"search": "cringe, emoticon, face, teeth"
	},
	{
		"icon": "grin",
		"search": "emoticon, face, laugh, smile"
	},
	{
		"icon": "grin-alt",
		"search": "emoticon, face, laugh, smile"
	},
	{
		"icon": "grin-beam",
		"search": "emoticon, face, laugh, smile"
	},
	{
		"icon": "grin-beam-sweat",
		"search": "embarass, emoticon, face, smile"
	},
	{
		"icon": "grin-hearts",
		"search": "emoticon, face, love, smile"
	},
	{
		"icon": "grin-squint",
		"search": "emoticon, face, laugh, smile"
	},
	{
		"icon": "grin-squint-tears",
		"search": "emoticon, face, happy, smile"
	},
	{
		"icon": "grin-stars",
		"search": "emoticon, face, star-struck"
	},
	{
		"icon": "grin-tears",
		"search": "LOL, emoticon, face"
	},
	{
		"icon": "grin-tongue",
		"search": "LOL, emoticon, face"
	},
	{
		"icon": "grin-tongue-squint",
		"search": "LOL, emoticon, face"
	},
	{
		"icon": "grin-tongue-wink",
		"search": "LOL, emoticon, face"
	},
	{
		"icon": "grin-wink",
		"search": "emoticon, face, flirt, laugh, smile"
	},
	{
		"icon": "grip-horizontal",
		"search": "affordance, drag, drop, grab, handle"
	},
	{
		"icon": "grip-lines",
		"search": "affordance, drag, drop, grab, handle"
	},
	{
		"icon": "grip-lines-vertical",
		"search": "affordance, drag, drop, grab, handle"
	},
	{
		"icon": "grip-vertical",
		"search": "affordance, drag, drop, grab, handle"
	},
	{
		"icon": "guitar",
		"search": "acoustic, instrument, music, rock, rock and roll, song, strings"
	},
	{
		"icon": "h-square",
		"search": "directions, emergency, hospital, hotel, map"
	},
	{
		"icon": "hamburger",
		"search": "bacon, beef, burger, burger king, cheeseburger, fast food, grill, ground beef, mcdonalds, sandwich"
	},
	{
		"icon": "hammer",
		"search": "admin, fix, repair, settings, tool"
	},
	{
		"icon": "hamsa",
		"search": "amulet, christianity, islam, jewish, judaism, muslim, protection"
	},
	{
		"icon": "hand-holding",
		"search": "carry, lift"
	},
	{
		"icon": "hand-holding-heart",
		"search": "carry, charity, gift, lift, package"
	},
	{
		"icon": "hand-holding-usd",
		"search": "$, carry, dollar sign, donation, giving, lift, money, price"
	},
	{
		"icon": "hand-lizard",
		"search": "game, roshambo"
	},
	{
		"icon": "hand-middle-finger",
		"search": "flip the bird, gesture, hate, rude"
	},
	{
		"icon": "hand-paper",
		"search": "game, halt, roshambo, stop"
	},
	{
		"icon": "hand-peace",
		"search": "rest, truce"
	},
	{
		"icon": "hand-point-down",
		"search": "finger, hand-o-down, point"
	},
	{
		"icon": "hand-point-left",
		"search": "back, finger, hand-o-left, left, point, previous"
	},
	{
		"icon": "hand-point-right",
		"search": "finger, forward, hand-o-right, next, point, right"
	},
	{
		"icon": "hand-point-up",
		"search": "finger, hand-o-up, point"
	},
	{
		"icon": "hand-pointer",
		"search": "arrow, cursor, select"
	},
	{
		"icon": "hand-rock",
		"search": "fist, game, roshambo"
	},
	{
		"icon": "hand-scissors",
		"search": "cut, game, roshambo"
	},
	{
		"icon": "hand-spock",
		"search": "live long, prosper, salute, star trek, vulcan"
	},
	{
		"icon": "hands",
		"search": "carry, hold, lift"
	},
	{
		"icon": "hands-helping",
		"search": "aid, assistance, handshake, partnership, volunteering"
	},
	{
		"icon": "handshake",
		"search": "agreement, greeting, meeting, partnership"
	},
	{
		"icon": "hanukiah",
		"search": "candle, hanukkah, jewish, judaism, light"
	},
	{
		"icon": "hard-hat",
		"search": "construction, hardhat, helmet, safety"
	},
	{
		"icon": "hashtag",
		"search": "Twitter, instagram, pound, social media, tag"
	},
	{
		"icon": "hat-cowboy",
		"search": "buckaroo, horse, jackeroo, john b., old west, pardner, ranch, rancher, rodeo, western, wrangler"
	},
	{
		"icon": "hat-cowboy-side",
		"search": "buckaroo, horse, jackeroo, john b., old west, pardner, ranch, rancher, rodeo, western, wrangler"
	},
	{
		"icon": "hat-wizard",
		"search": "Dungeons & Dragons, accessory, buckle, clothing, d&d, dnd, fantasy, halloween, head, holiday, mage, magic, pointy, witch"
	},
	{
		"icon": "hdd",
		"search": "cpu, hard drive, harddrive, machine, save, storage"
	},
	{
		"icon": "heading",
		"search": "format, header, text, title"
	},
	{
		"icon": "headphones",
		"search": "audio, listen, music, sound, speaker"
	},
	{
		"icon": "headphones-alt",
		"search": "audio, listen, music, sound, speaker"
	},
	{
		"icon": "headset",
		"search": "audio, gamer, gaming, listen, live chat, microphone, shot caller, sound, support, telemarketer"
	},
	{
		"icon": "heart",
		"search": "favorite, like, love, relationship, valentine"
	},
	{
		"icon": "heart-broken",
		"search": "breakup, crushed, dislike, dumped, grief, love, lovesick, relationship, sad"
	},
	{
		"icon": "heartbeat",
		"search": "ekg, electrocardiogram, health, lifeline, vital signs"
	},
	{
		"icon": "helicopter",
		"search": "airwolf, apache, chopper, flight, fly, travel"
	},
	{
		"icon": "highlighter",
		"search": "edit, marker, sharpie, update, write"
	},
	{
		"icon": "hiking",
		"search": "activity, backpack, fall, fitness, outdoors, person, seasonal, walking"
	},
	{
		"icon": "hippo",
		"search": "animal, fauna, hippopotamus, hungry, mammal"
	},
	{
		"icon": "history",
		"search": "Rewind, clock, reverse, time, time machine"
	},
	{
		"icon": "hockey-puck",
		"search": "ice, nhl, sport"
	},
	{
		"icon": "holly-berry",
		"search": "catwoman, christmas, decoration, flora, halle, holiday, ororo munroe, plant, storm, xmas"
	},
	{
		"icon": "home",
		"search": "abode, building, house, main"
	},
	{
		"icon": "horse",
		"search": "equus, fauna, mammmal, mare, neigh, pony"
	},
	{
		"icon": "horse-head",
		"search": "equus, fauna, mammmal, mare, neigh, pony"
	},
	{
		"icon": "hospital",
		"search": "building, emergency room, medical center"
	},
	{
		"icon": "hospital-alt",
		"search": "building, emergency room, medical center"
	},
	{
		"icon": "hospital-symbol",
		"search": "clinic, emergency, map"
	},
	{
		"icon": "hot-tub",
		"search": "bath, jacuzzi, massage, sauna, spa"
	},
	{
		"icon": "hotdog",
		"search": "bun, chili, frankfurt, frankfurter, kosher, polish, sandwich, sausage, vienna, weiner"
	},
	{
		"icon": "hotel",
		"search": "building, inn, lodging, motel, resort, travel"
	},
	{
		"icon": "hourglass",
		"search": "hour, minute, sand, stopwatch, time"
	},
	{
		"icon": "hourglass-end",
		"search": "hour, minute, sand, stopwatch, time"
	},
	{
		"icon": "hourglass-half",
		"search": "hour, minute, sand, stopwatch, time"
	},
	{
		"icon": "hourglass-start",
		"search": "hour, minute, sand, stopwatch, time"
	},
	{
		"icon": "house-damage",
		"search": "building, devastation, disaster, home, insurance"
	},
	{
		"icon": "hryvnia",
		"search": "currency, money, ukraine, ukrainian"
	},
	{
		"icon": "i-cursor",
		"search": "editing, i-beam, type, writing"
	},
	{
		"icon": "ice-cream",
		"search": "chocolate, cone, dessert, frozen, scoop, sorbet, vanilla, yogurt"
	},
	{
		"icon": "icicles",
		"search": "cold, frozen, hanging, ice, seasonal, sharp"
	},
	{
		"icon": "icons",
		"search": "bolt, emoji, heart, image, music, photo, symbols"
	},
	{
		"icon": "id-badge",
		"search": "address, contact, identification, license, profile"
	},
	{
		"icon": "id-card",
		"search": "contact, demographics, document, identification, issued, profile"
	},
	{
		"icon": "id-card-alt",
		"search": "contact, demographics, document, identification, issued, profile"
	},
	{
		"icon": "igloo",
		"search": "dome, dwelling, eskimo, home, house, ice, snow"
	},
	{
		"icon": "image",
		"search": "album, landscape, photo, picture"
	},
	{
		"icon": "images",
		"search": "album, landscape, photo, picture"
	},
	{
		"icon": "inbox",
		"search": "archive, desk, email, mail, message"
	},
	{
		"icon": "indent",
		"search": "align, justify, paragraph, tab"
	},
	{
		"icon": "industry",
		"search": "building, factory, industrial, manufacturing, mill, warehouse"
	},
	{
		"icon": "infinity",
		"search": "eternity, forever, math"
	},
	{
		"icon": "info",
		"search": "details, help, information, more, support"
	},
	{
		"icon": "info-circle",
		"search": "details, help, information, more, support"
	},
	{
		"icon": "italic",
		"search": "edit, emphasis, font, format, text, type"
	},
	{
		"icon": "jedi",
		"search": "crest, force, sith, skywalker, star wars, yoda"
	},
	{
		"icon": "joint",
		"search": "blunt, cannabis, doobie, drugs, marijuana, roach, smoke, smoking, spliff"
	},
	{
		"icon": "journal-whills",
		"search": "book, force, jedi, sith, star wars, yoda"
	},
	{
		"icon": "kaaba",
		"search": "building, cube, islam, muslim"
	},
	{
		"icon": "key",
		"search": "lock, password, private, secret, unlock"
	},
	{
		"icon": "keyboard",
		"search": "accessory, edit, input, text, type, write"
	},
	{
		"icon": "khanda",
		"search": "chakkar, sikh, sikhism, sword"
	},
	{
		"icon": "kiss",
		"search": "beso, emoticon, face, love, smooch"
	},
	{
		"icon": "kiss-beam",
		"search": "beso, emoticon, face, love, smooch"
	},
	{
		"icon": "kiss-wink-heart",
		"search": "beso, emoticon, face, love, smooch"
	},
	{
		"icon": "kiwi-bird",
		"search": "bird, fauna, new zealand"
	},
	{
		"icon": "landmark",
		"search": "building, historic, memorable, monument, politics"
	},
	{
		"icon": "language",
		"search": "dialect, idiom, localize, speech, translate, vernacular"
	},
	{
		"icon": "laptop",
		"search": "computer, cpu, dell, demo, device, mac, macbook, machine, pc"
	},
	{
		"icon": "laptop-code",
		"search": "computer, cpu, dell, demo, develop, device, mac, macbook, machine, pc"
	},
	{
		"icon": "laptop-medical",
		"search": "computer, device, ehr, electronic health records, history"
	},
	{
		"icon": "laugh",
		"search": "LOL, emoticon, face, laugh, smile"
	},
	{
		"icon": "laugh-beam",
		"search": "LOL, emoticon, face, happy, smile"
	},
	{
		"icon": "laugh-squint",
		"search": "LOL, emoticon, face, happy, smile"
	},
	{
		"icon": "laugh-wink",
		"search": "LOL, emoticon, face, happy, smile"
	},
	{
		"icon": "layer-group",
		"search": "arrange, develop, layers, map, stack"
	},
	{
		"icon": "leaf",
		"search": "eco, flora, nature, plant, vegan"
	},
	{
		"icon": "lemon",
		"search": "citrus, lemonade, lime, tart"
	},
	{
		"icon": "less-than",
		"search": "arithmetic, compare, math"
	},
	{
		"icon": "less-than-equal",
		"search": "arithmetic, compare, math"
	},
	{
		"icon": "level-down-alt",
		"search": "arrow, level-down"
	},
	{
		"icon": "level-up-alt",
		"search": "arrow, level-up"
	},
	{
		"icon": "life-ring",
		"search": "coast guard, help, overboard, save, support"
	},
	{
		"icon": "lightbulb",
		"search": "energy, idea, inspiration, light"
	},
	{
		"icon": "link",
		"search": "attach, attachment, chain, connect"
	},
	{
		"icon": "lira-sign",
		"search": "currency, money, try, turkish"
	},
	{
		"icon": "list",
		"search": "checklist, completed, done, finished, ol, todo, ul"
	},
	{
		"icon": "list-alt",
		"search": "checklist, completed, done, finished, ol, todo, ul"
	},
	{
		"icon": "list-ol",
		"search": "checklist, completed, done, finished, numbers, ol, todo, ul"
	},
	{
		"icon": "list-ul",
		"search": "checklist, completed, done, finished, ol, todo, ul"
	},
	{
		"icon": "location-arrow",
		"search": "address, compass, coordinate, direction, gps, map, navigation, place"
	},
	{
		"icon": "lock",
		"search": "admin, lock, open, password, private, protect, security"
	},
	{
		"icon": "lock-open",
		"search": "admin, lock, open, password, private, protect, security"
	},
	{
		"icon": "long-arrow-alt-down",
		"search": "download, long-arrow-down"
	},
	{
		"icon": "long-arrow-alt-left",
		"search": "back, long-arrow-left, previous"
	},
	{
		"icon": "long-arrow-alt-right",
		"search": "forward, long-arrow-right, next"
	},
	{
		"icon": "long-arrow-alt-up",
		"search": "long-arrow-up, upload"
	},
	{
		"icon": "low-vision",
		"search": "blind, eye, sight"
	},
	{
		"icon": "luggage-cart",
		"search": "bag, baggage, suitcase, travel"
	},
	{
		"icon": "magic",
		"search": "autocomplete, automatic, mage, magic, spell, wand, witch, wizard"
	},
	{
		"icon": "magnet",
		"search": "Attract, lodestone, tool"
	},
	{
		"icon": "mail-bulk",
		"search": "archive, envelope, letter, post office, postal, postcard, send, stamp, usps"
	},
	{
		"icon": "male",
		"search": "human, man, person, profile, user"
	},
	{
		"icon": "map",
		"search": "address, coordinates, destination, gps, localize, location, map, navigation, paper, pin, place, point of interest, position, route, travel"
	},
	{
		"icon": "map-marked",
		"search": "address, coordinates, destination, gps, localize, location, map, navigation, paper, pin, place, point of interest, position, route, travel"
	},
	{
		"icon": "map-marked-alt",
		"search": "address, coordinates, destination, gps, localize, location, map, navigation, paper, pin, place, point of interest, position, route, travel"
	},
	{
		"icon": "map-marker",
		"search": "address, coordinates, destination, gps, localize, location, map, navigation, paper, pin, place, point of interest, position, route, travel"
	},
	{
		"icon": "map-marker-alt",
		"search": "address, coordinates, destination, gps, localize, location, map, navigation, paper, pin, place, point of interest, position, route, travel"
	},
	{
		"icon": "map-pin",
		"search": "address, agree, coordinates, destination, gps, localize, location, map, marker, navigation, pin, place, position, travel"
	},
	{
		"icon": "map-signs",
		"search": "directions, directory, map, signage, wayfinding"
	},
	{
		"icon": "marker",
		"search": "design, edit, sharpie, update, write"
	},
	{
		"icon": "mars",
		"search": "male"
	},
	{
		"icon": "mars-double",
		"search": ""
	},
	{
		"icon": "mars-stroke",
		"search": ""
	},
	{
		"icon": "mars-stroke-h",
		"search": ""
	},
	{
		"icon": "mars-stroke-v",
		"search": ""
	},
	{
		"icon": "mask",
		"search": "carnivale, costume, disguise, halloween, secret, super hero"
	},
	{
		"icon": "medal",
		"search": "award, ribbon, star, trophy"
	},
	{
		"icon": "medkit",
		"search": "first aid, firstaid, health, help, support"
	},
	{
		"icon": "meh",
		"search": "emoticon, face, neutral, rating"
	},
	{
		"icon": "meh-blank",
		"search": "emoticon, face, neutral, rating"
	},
	{
		"icon": "meh-rolling-eyes",
		"search": "emoticon, face, neutral, rating"
	},
	{
		"icon": "memory",
		"search": "DIMM, RAM, hardware, storage, technology"
	},
	{
		"icon": "menorah",
		"search": "candle, hanukkah, jewish, judaism, light"
	},
	{
		"icon": "mercury",
		"search": "transgender"
	},
	{
		"icon": "meteor",
		"search": "armageddon, asteroid, comet, shooting star, space"
	},
	{
		"icon": "microchip",
		"search": "cpu, hardware, processor, technology"
	},
	{
		"icon": "microphone",
		"search": "audio, podcast, record, sing, sound, voice"
	},
	{
		"icon": "microphone-alt",
		"search": "audio, podcast, record, sing, sound, voice"
	},
	{
		"icon": "microphone-alt-slash",
		"search": "audio, disable, mute, podcast, record, sing, sound, voice"
	},
	{
		"icon": "microphone-slash",
		"search": "audio, disable, mute, podcast, record, sing, sound, voice"
	},
	{
		"icon": "microscope",
		"search": "electron, lens, optics, science, shrink"
	},
	{
		"icon": "minus",
		"search": "collapse, delete, hide, minify, negative, remove, trash"
	},
	{
		"icon": "minus-circle",
		"search": "delete, hide, negative, remove, shape, trash"
	},
	{
		"icon": "minus-square",
		"search": "collapse, delete, hide, minify, negative, remove, shape, trash"
	},
	{
		"icon": "mitten",
		"search": "clothing, cold, glove, hands, knitted, seasonal, warmth"
	},
	{
		"icon": "mobile",
		"search": "apple, call, cell phone, cellphone, device, iphone, number, screen, telephone"
	},
	{
		"icon": "mobile-alt",
		"search": "apple, call, cell phone, cellphone, device, iphone, number, screen, telephone"
	},
	{
		"icon": "money-bill",
		"search": "buy, cash, checkout, money, payment, price, purchase"
	},
	{
		"icon": "money-bill-alt",
		"search": "buy, cash, checkout, money, payment, price, purchase"
	},
	{
		"icon": "money-bill-wave",
		"search": "buy, cash, checkout, money, payment, price, purchase"
	},
	{
		"icon": "money-bill-wave-alt",
		"search": "buy, cash, checkout, money, payment, price, purchase"
	},
	{
		"icon": "money-check",
		"search": "bank check, buy, checkout, cheque, money, payment, price, purchase"
	},
	{
		"icon": "money-check-alt",
		"search": "bank check, buy, checkout, cheque, money, payment, price, purchase"
	},
	{
		"icon": "monument",
		"search": "building, historic, landmark, memorable"
	},
	{
		"icon": "moon",
		"search": "contrast, crescent, dark, lunar, night"
	},
	{
		"icon": "mortar-pestle",
		"search": "crush, culinary, grind, medical, mix, pharmacy, prescription, spices"
	},
	{
		"icon": "mosque",
		"search": "building, islam, landmark, muslim"
	},
	{
		"icon": "motorcycle",
		"search": "bike, machine, transportation, vehicle"
	},
	{
		"icon": "mountain",
		"search": "glacier, hiking, hill, landscape, travel, view"
	},
	{
		"icon": "mouse",
		"search": "click, computer, cursor, input, peripheral"
	},
	{
		"icon": "mouse-pointer",
		"search": "arrow, cursor, select"
	},
	{
		"icon": "mug-hot",
		"search": "caliente, cocoa, coffee, cup, drink, holiday, hot chocolate, steam, tea, warmth"
	},
	{
		"icon": "music",
		"search": "lyrics, melody, note, sing, sound"
	},
	{
		"icon": "network-wired",
		"search": "computer, connect, ethernet, internet, intranet"
	},
	{
		"icon": "neuter",
		"search": ""
	},
	{
		"icon": "newspaper",
		"search": "article, editorial, headline, journal, journalism, news, press"
	},
	{
		"icon": "not-equal",
		"search": "arithmetic, compare, math"
	},
	{
		"icon": "notes-medical",
		"search": "clipboard, doctor, ehr, health, history, records"
	},
	{
		"icon": "object-group",
		"search": "combine, copy, design, merge, select"
	},
	{
		"icon": "object-ungroup",
		"search": "copy, design, merge, select, separate"
	},
	{
		"icon": "oil-can",
		"search": "auto, crude, gasoline, grease, lubricate, petroleum"
	},
	{
		"icon": "om",
		"search": "buddhism, hinduism, jainism, mantra"
	},
	{
		"icon": "otter",
		"search": "animal, badger, fauna, fur, mammal, marten"
	},
	{
		"icon": "outdent",
		"search": "align, justify, paragraph, tab"
	},
	{
		"icon": "pager",
		"search": "beeper, cellphone, communication"
	},
	{
		"icon": "paint-brush",
		"search": "acrylic, art, brush, color, fill, paint, pigment, watercolor"
	},
	{
		"icon": "paint-roller",
		"search": "acrylic, art, brush, color, fill, paint, pigment, watercolor"
	},
	{
		"icon": "palette",
		"search": "acrylic, art, brush, color, fill, paint, pigment, watercolor"
	},
	{
		"icon": "pallet",
		"search": "archive, box, inventory, shipping, warehouse"
	},
	{
		"icon": "paper-plane",
		"search": "air, float, fold, mail, paper, send"
	},
	{
		"icon": "paperclip",
		"search": "attach, attachment, connect, link"
	},
	{
		"icon": "parachute-box",
		"search": "aid, assistance, rescue, supplies"
	},
	{
		"icon": "paragraph",
		"search": "edit, format, text, writing"
	},
	{
		"icon": "parking",
		"search": "auto, car, garage, meter"
	},
	{
		"icon": "passport",
		"search": "document, id, identification, issued, travel"
	},
	{
		"icon": "pastafarianism",
		"search": "agnosticism, atheism, flying spaghetti monster, fsm"
	},
	{
		"icon": "paste",
		"search": "clipboard, copy, document, paper"
	},
	{
		"icon": "pause",
		"search": "hold, wait"
	},
	{
		"icon": "pause-circle",
		"search": "hold, wait"
	},
	{
		"icon": "paw",
		"search": "animal, cat, dog, pet, print"
	},
	{
		"icon": "peace",
		"search": "serenity, tranquility, truce, war"
	},
	{
		"icon": "pen",
		"search": "design, edit, update, write"
	},
	{
		"icon": "pen-alt",
		"search": "design, edit, update, write"
	},
	{
		"icon": "pen-fancy",
		"search": "design, edit, fountain pen, update, write"
	},
	{
		"icon": "pen-nib",
		"search": "design, edit, fountain pen, update, write"
	},
	{
		"icon": "pen-square",
		"search": "edit, pencil-square, update, write"
	},
	{
		"icon": "pencil-alt",
		"search": "design, edit, pencil, update, write"
	},
	{
		"icon": "pencil-ruler",
		"search": "design, draft, draw, pencil"
	},
	{
		"icon": "people-carry",
		"search": "box, carry, fragile, help, movers, package"
	},
	{
		"icon": "pepper-hot",
		"search": "buffalo wings, capsicum, chili, chilli, habanero, jalapeno, mexican, spicy, tabasco, vegetable"
	},
	{
		"icon": "percent",
		"search": "discount, fraction, proportion, rate, ratio"
	},
	{
		"icon": "percentage",
		"search": "discount, fraction, proportion, rate, ratio"
	},
	{
		"icon": "person-booth",
		"search": "changing, changing room, election, human, person, vote, voting"
	},
	{
		"icon": "phone",
		"search": "call, earphone, number, support, telephone, voice"
	},
	{
		"icon": "phone-alt",
		"search": "call, earphone, number, support, telephone, voice"
	},
	{
		"icon": "phone-slash",
		"search": "call, cancel, earphone, mute, number, support, telephone, voice"
	},
	{
		"icon": "phone-square",
		"search": "call, earphone, number, support, telephone, voice"
	},
	{
		"icon": "phone-square-alt",
		"search": "call, earphone, number, support, telephone, voice"
	},
	{
		"icon": "phone-volume",
		"search": "call, earphone, number, sound, support, telephone, voice, volume-control-phone"
	},
	{
		"icon": "photo-video",
		"search": "av, film, image, library, media"
	},
	{
		"icon": "piggy-bank",
		"search": "bank, save, savings"
	},
	{
		"icon": "pills",
		"search": "drugs, medicine, prescription, tablets"
	},
	{
		"icon": "pizza-slice",
		"search": "cheese, chicago, italian, mozzarella, new york, pepperoni, pie, slice, teenage mutant ninja turtles, tomato"
	},
	{
		"icon": "place-of-worship",
		"search": "building, church, holy, mosque, synagogue"
	},
	{
		"icon": "plane",
		"search": "airplane, destination, fly, location, mode, travel, trip"
	},
	{
		"icon": "plane-arrival",
		"search": "airplane, arriving, destination, fly, land, landing, location, mode, travel, trip"
	},
	{
		"icon": "plane-departure",
		"search": "airplane, departing, destination, fly, location, mode, take off, taking off, travel, trip"
	},
	{
		"icon": "play",
		"search": "audio, music, playing, sound, start, video"
	},
	{
		"icon": "play-circle",
		"search": "audio, music, playing, sound, start, video"
	},
	{
		"icon": "plug",
		"search": "connect, electric, online, power"
	},
	{
		"icon": "plus",
		"search": "add, create, expand, new, positive, shape"
	},
	{
		"icon": "plus-circle",
		"search": "add, create, expand, new, positive, shape"
	},
	{
		"icon": "plus-square",
		"search": "add, create, expand, new, positive, shape"
	},
	{
		"icon": "podcast",
		"search": "audio, broadcast, music, sound"
	},
	{
		"icon": "poll",
		"search": "results, survey, trend, vote, voting"
	},
	{
		"icon": "poll-h",
		"search": "results, survey, trend, vote, voting"
	},
	{
		"icon": "poo",
		"search": "crap, poop, shit, smile, turd"
	},
	{
		"icon": "poo-storm",
		"search": "bolt, cloud, euphemism, lightning, mess, poop, shit, turd"
	},
	{
		"icon": "poop",
		"search": "crap, poop, shit, smile, turd"
	},
	{
		"icon": "portrait",
		"search": "id, image, photo, picture, selfie"
	},
	{
		"icon": "pound-sign",
		"search": "currency, gbp, money"
	},
	{
		"icon": "power-off",
		"search": "cancel, computer, on, reboot, restart"
	},
	{
		"icon": "pray",
		"search": "kneel, preach, religion, worship"
	},
	{
		"icon": "praying-hands",
		"search": "kneel, preach, religion, worship"
	},
	{
		"icon": "prescription",
		"search": "drugs, medical, medicine, pharmacy, rx"
	},
	{
		"icon": "prescription-bottle",
		"search": "drugs, medical, medicine, pharmacy, rx"
	},
	{
		"icon": "prescription-bottle-alt",
		"search": "drugs, medical, medicine, pharmacy, rx"
	},
	{
		"icon": "print",
		"search": "business, copy, document, office, paper"
	},
	{
		"icon": "procedures",
		"search": "EKG, bed, electrocardiogram, health, hospital, life, patient, vital"
	},
	{
		"icon": "project-diagram",
		"search": "chart, graph, network, pert"
	},
	{
		"icon": "puzzle-piece",
		"search": "add-on, addon, game, section"
	},
	{
		"icon": "qrcode",
		"search": "barcode, info, information, scan"
	},
	{
		"icon": "question",
		"search": "help, information, support, unknown"
	},
	{
		"icon": "question-circle",
		"search": "help, information, support, unknown"
	},
	{
		"icon": "quidditch",
		"search": "ball, bludger, broom, golden snitch, harry potter, hogwarts, quaffle, sport, wizard"
	},
	{
		"icon": "quote-left",
		"search": "mention, note, phrase, text, type"
	},
	{
		"icon": "quote-right",
		"search": "mention, note, phrase, text, type"
	},
	{
		"icon": "quran",
		"search": "book, islam, muslim, religion"
	},
	{
		"icon": "radiation",
		"search": "danger, dangerous, deadly, hazard, nuclear, radioactive, warning"
	},
	{
		"icon": "radiation-alt",
		"search": "danger, dangerous, deadly, hazard, nuclear, radioactive, warning"
	},
	{
		"icon": "rainbow",
		"search": "gold, leprechaun, prism, rain, sky"
	},
	{
		"icon": "random",
		"search": "arrows, shuffle, sort, swap, switch, transfer"
	},
	{
		"icon": "receipt",
		"search": "check, invoice, money, pay, table"
	},
	{
		"icon": "record-vinyl",
		"search": "LP, album, analog, music, phonograph, sound"
	},
	{
		"icon": "recycle",
		"search": "Waste, compost, garbage, reuse, trash"
	},
	{
		"icon": "redo",
		"search": "forward, refresh, reload, repeat"
	},
	{
		"icon": "redo-alt",
		"search": "forward, refresh, reload, repeat"
	},
	{
		"icon": "registered",
		"search": "copyright, mark, trademark"
	},
	{
		"icon": "remove-format",
		"search": "cancel, font, format, remove, style, text"
	},
	{
		"icon": "reply",
		"search": "mail, message, respond"
	},
	{
		"icon": "reply-all",
		"search": "mail, message, respond"
	},
	{
		"icon": "republican",
		"search": "american, conservative, election, elephant, politics, republican party, right, right-wing, usa"
	},
	{
		"icon": "restroom",
		"search": "bathroom, john, loo, potty, washroom, waste, wc"
	},
	{
		"icon": "retweet",
		"search": "refresh, reload, share, swap"
	},
	{
		"icon": "ribbon",
		"search": "badge, cause, lapel, pin"
	},
	{
		"icon": "ring",
		"search": "Dungeons & Dragons, Gollum, band, binding, d&d, dnd, engagement, fantasy, gold, jewelry, marriage, precious"
	},
	{
		"icon": "road",
		"search": "highway, map, pavement, route, street, travel"
	},
	{
		"icon": "robot",
		"search": "android, automate, computer, cyborg"
	},
	{
		"icon": "rocket",
		"search": "aircraft, app, jet, launch, nasa, space"
	},
	{
		"icon": "route",
		"search": "directions, navigation, travel"
	},
	{
		"icon": "rss",
		"search": "blog, feed, journal, news, writing"
	},
	{
		"icon": "rss-square",
		"search": "blog, feed, journal, news, writing"
	},
	{
		"icon": "ruble-sign",
		"search": "currency, money, rub"
	},
	{
		"icon": "ruler",
		"search": "design, draft, length, measure, planning"
	},
	{
		"icon": "ruler-combined",
		"search": "design, draft, length, measure, planning"
	},
	{
		"icon": "ruler-horizontal",
		"search": "design, draft, length, measure, planning"
	},
	{
		"icon": "ruler-vertical",
		"search": "design, draft, length, measure, planning"
	},
	{
		"icon": "running",
		"search": "exercise, health, jog, person, run, sport, sprint"
	},
	{
		"icon": "rupee-sign",
		"search": "currency, indian, inr, money"
	},
	{
		"icon": "sad-cry",
		"search": "emoticon, face, tear, tears"
	},
	{
		"icon": "sad-tear",
		"search": "emoticon, face, tear, tears"
	},
	{
		"icon": "satellite",
		"search": "communications, hardware, orbit, space"
	},
	{
		"icon": "satellite-dish",
		"search": "SETI, communications, hardware, receiver, saucer, signal, space"
	},
	{
		"icon": "save",
		"search": "disk, download, floppy, floppy-o"
	},
	{
		"icon": "school",
		"search": "building, education, learn, student, teacher"
	},
	{
		"icon": "screwdriver",
		"search": "admin, fix, mechanic, repair, settings, tool"
	},
	{
		"icon": "scroll",
		"search": "Dungeons & Dragons, announcement, d&d, dnd, fantasy, paper, script"
	},
	{
		"icon": "sd-card",
		"search": "image, memory, photo, save"
	},
	{
		"icon": "search",
		"search": "bigger, enlarge, find, magnify, preview, zoom"
	},
	{
		"icon": "search-dollar",
		"search": "bigger, enlarge, find, magnify, money, preview, zoom"
	},
	{
		"icon": "search-location",
		"search": "bigger, enlarge, find, magnify, preview, zoom"
	},
	{
		"icon": "search-minus",
		"search": "minify, negative, smaller, zoom, zoom out"
	},
	{
		"icon": "search-plus",
		"search": "bigger, enlarge, magnify, positive, zoom, zoom in"
	},
	{
		"icon": "seedling",
		"search": "flora, grow, plant, vegan"
	},
	{
		"icon": "server",
		"search": "computer, cpu, database, hardware, network"
	},
	{
		"icon": "shapes",
		"search": "blocks, build, circle, square, triangle"
	},
	{
		"icon": "share",
		"search": "forward, save, send, social"
	},
	{
		"icon": "share-alt",
		"search": "forward, save, send, social"
	},
	{
		"icon": "share-alt-square",
		"search": "forward, save, send, social"
	},
	{
		"icon": "share-square",
		"search": "forward, save, send, social"
	},
	{
		"icon": "shekel-sign",
		"search": "currency, ils, money"
	},
	{
		"icon": "shield-alt",
		"search": "achievement, award, block, defend, security, winner"
	},
	{
		"icon": "ship",
		"search": "boat, sea, water"
	},
	{
		"icon": "shipping-fast",
		"search": "express, fedex, mail, overnight, package, ups"
	},
	{
		"icon": "shoe-prints",
		"search": "feet, footprints, steps, walk"
	},
	{
		"icon": "shopping-bag",
		"search": "buy, checkout, grocery, payment, purchase"
	},
	{
		"icon": "shopping-basket",
		"search": "buy, checkout, grocery, payment, purchase"
	},
	{
		"icon": "shopping-cart",
		"search": "buy, checkout, grocery, payment, purchase"
	},
	{
		"icon": "shower",
		"search": "bath, clean, faucet, water"
	},
	{
		"icon": "shuttle-van",
		"search": "airport, machine, public-transportation, transportation, travel, vehicle"
	},
	{
		"icon": "sign",
		"search": "directions, real estate, signage, wayfinding"
	},
	{
		"icon": "sign-in-alt",
		"search": "arrow, enter, join, log in, login, sign in, sign up, sign-in, signin, signup"
	},
	{
		"icon": "sign-language",
		"search": "Translate, asl, deaf, hands"
	},
	{
		"icon": "sign-out-alt",
		"search": "arrow, exit, leave, log out, logout, sign-out"
	},
	{
		"icon": "signal",
		"search": "bars, graph, online, reception, status"
	},
	{
		"icon": "signature",
		"search": "John Hancock, cursive, name, writing"
	},
	{
		"icon": "sim-card",
		"search": "hard drive, hardware, portable, storage, technology, tiny"
	},
	{
		"icon": "sitemap",
		"search": "directory, hierarchy, ia, information architecture, organization"
	},
	{
		"icon": "skating",
		"search": "activity, figure skating, fitness, ice, person, winter"
	},
	{
		"icon": "skiing",
		"search": "activity, downhill, fast, fitness, olympics, outdoors, person, seasonal, slalom"
	},
	{
		"icon": "skiing-nordic",
		"search": "activity, cross country, fitness, outdoors, person, seasonal"
	},
	{
		"icon": "skull",
		"search": "bones, skeleton, x-ray, yorick"
	},
	{
		"icon": "skull-crossbones",
		"search": "Dungeons & Dragons, alert, bones, d&d, danger, dead, deadly, death, dnd, fantasy, halloween, holiday, jolly-roger, pirate, poison, skeleton, warning"
	},
	{
		"icon": "slash",
		"search": "cancel, close, mute, off, stop, x"
	},
	{
		"icon": "sleigh",
		"search": "christmas, claus, fly, holiday, santa, sled, snow, xmas"
	},
	{
		"icon": "sliders-h",
		"search": "adjust, settings, sliders, toggle"
	},
	{
		"icon": "smile",
		"search": "approve, emoticon, face, happy, rating, satisfied"
	},
	{
		"icon": "smile-beam",
		"search": "emoticon, face, happy, positive"
	},
	{
		"icon": "smile-wink",
		"search": "emoticon, face, happy, hint, joke"
	},
	{
		"icon": "smog",
		"search": "dragon, fog, haze, pollution, smoke, weather"
	},
	{
		"icon": "smoking",
		"search": "cancer, cigarette, nicotine, smoking status, tobacco"
	},
	{
		"icon": "smoking-ban",
		"search": "ban, cancel, no smoking, non-smoking"
	},
	{
		"icon": "sms",
		"search": "chat, conversation, message, mobile, notification, phone, sms, texting"
	},
	{
		"icon": "snowboarding",
		"search": "activity, fitness, olympics, outdoors, person"
	},
	{
		"icon": "snowflake",
		"search": "precipitation, rain, winter"
	},
	{
		"icon": "snowman",
		"search": "decoration, frost, frosty, holiday"
	},
	{
		"icon": "snowplow",
		"search": "clean up, cold, road, storm, winter"
	},
	{
		"icon": "socks",
		"search": "business socks, business time, clothing, feet, flight of the conchords, wednesday"
	},
	{
		"icon": "solar-panel",
		"search": "clean, eco-friendly, energy, green, sun"
	},
	{
		"icon": "sort",
		"search": "filter, order"
	},
	{
		"icon": "sort-alpha-down",
		"search": "alphabetical, arrange, filter, order, sort-alpha-asc"
	},
	{
		"icon": "sort-alpha-down-alt",
		"search": "alphabetical, arrange, filter, order, sort-alpha-asc"
	},
	{
		"icon": "sort-alpha-up",
		"search": "alphabetical, arrange, filter, order, sort-alpha-desc"
	},
	{
		"icon": "sort-alpha-up-alt",
		"search": "alphabetical, arrange, filter, order, sort-alpha-desc"
	},
	{
		"icon": "sort-amount-down",
		"search": "arrange, filter, number, order, sort-amount-asc"
	},
	{
		"icon": "sort-amount-down-alt",
		"search": "arrange, filter, order, sort-amount-asc"
	},
	{
		"icon": "sort-amount-up",
		"search": "arrange, filter, order, sort-amount-desc"
	},
	{
		"icon": "sort-amount-up-alt",
		"search": "arrange, filter, order, sort-amount-desc"
	},
	{
		"icon": "sort-down",
		"search": "arrow, descending, filter, order, sort-desc"
	},
	{
		"icon": "sort-numeric-down",
		"search": "arrange, filter, numbers, order, sort-numeric-asc"
	},
	{
		"icon": "sort-numeric-down-alt",
		"search": "arrange, filter, numbers, order, sort-numeric-asc"
	},
	{
		"icon": "sort-numeric-up",
		"search": "arrange, filter, numbers, order, sort-numeric-desc"
	},
	{
		"icon": "sort-numeric-up-alt",
		"search": "arrange, filter, numbers, order, sort-numeric-desc"
	},
	{
		"icon": "sort-up",
		"search": "arrow, ascending, filter, order, sort-asc"
	},
	{
		"icon": "spa",
		"search": "flora, massage, mindfulness, plant, wellness"
	},
	{
		"icon": "space-shuttle",
		"search": "astronaut, machine, nasa, rocket, space, transportation"
	},
	{
		"icon": "spell-check",
		"search": "dictionary, edit, editor, grammar, text"
	},
	{
		"icon": "spider",
		"search": "arachnid, bug, charlotte, crawl, eight, halloween"
	},
	{
		"icon": "spinner",
		"search": "circle, loading, progress"
	},
	{
		"icon": "splotch",
		"search": "Ink, blob, blotch, glob, stain"
	},
	{
		"icon": "spray-can",
		"search": "Paint, aerosol, design, graffiti, tag"
	},
	{
		"icon": "square",
		"search": "block, box, shape"
	},
	{
		"icon": "square-full",
		"search": "block, box, shape"
	},
	{
		"icon": "square-root-alt",
		"search": "arithmetic, calculus, division, math"
	},
	{
		"icon": "stamp",
		"search": "art, certificate, imprint, rubber, seal"
	},
	{
		"icon": "star",
		"search": "achievement, award, favorite, important, night, rating, score"
	},
	{
		"icon": "star-and-crescent",
		"search": "islam, muslim, religion"
	},
	{
		"icon": "star-half",
		"search": "achievement, award, rating, score, star-half-empty, star-half-full"
	},
	{
		"icon": "star-half-alt",
		"search": "achievement, award, rating, score, star-half-empty, star-half-full"
	},
	{
		"icon": "star-of-david",
		"search": "jewish, judaism, religion"
	},
	{
		"icon": "star-of-life",
		"search": "doctor, emt, first aid, health, medical"
	},
	{
		"icon": "step-backward",
		"search": "beginning, first, previous, rewind, start"
	},
	{
		"icon": "step-forward",
		"search": "end, last, next"
	},
	{
		"icon": "stethoscope",
		"search": "diagnosis, doctor, general practitioner, hospital, infirmary, medicine, office, outpatient"
	},
	{
		"icon": "sticky-note",
		"search": "message, note, paper, reminder, sticker"
	},
	{
		"icon": "stop",
		"search": "block, box, square"
	},
	{
		"icon": "stop-circle",
		"search": "block, box, circle, square"
	},
	{
		"icon": "stopwatch",
		"search": "clock, reminder, time"
	},
	{
		"icon": "store",
		"search": "building, buy, purchase, shopping"
	},
	{
		"icon": "store-alt",
		"search": "building, buy, purchase, shopping"
	},
	{
		"icon": "stream",
		"search": "flow, list, timeline"
	},
	{
		"icon": "street-view",
		"search": "directions, location, map, navigation"
	},
	{
		"icon": "strikethrough",
		"search": "cancel, edit, font, format, text, type"
	},
	{
		"icon": "stroopwafel",
		"search": "caramel, cookie, dessert, sweets, waffle"
	},
	{
		"icon": "subscript",
		"search": "edit, font, format, text, type"
	},
	{
		"icon": "subway",
		"search": "machine, railway, train, transportation, vehicle"
	},
	{
		"icon": "suitcase",
		"search": "baggage, luggage, move, suitcase, travel, trip"
	},
	{
		"icon": "suitcase-rolling",
		"search": "baggage, luggage, move, suitcase, travel, trip"
	},
	{
		"icon": "sun",
		"search": "brighten, contrast, day, lighter, sol, solar, star, weather"
	},
	{
		"icon": "superscript",
		"search": "edit, exponential, font, format, text, type"
	},
	{
		"icon": "surprise",
		"search": "emoticon, face, shocked"
	},
	{
		"icon": "swatchbook",
		"search": "Pantone, color, design, hue, palette"
	},
	{
		"icon": "swimmer",
		"search": "athlete, head, man, olympics, person, pool, water"
	},
	{
		"icon": "swimming-pool",
		"search": "ladder, recreation, swim, water"
	},
	{
		"icon": "synagogue",
		"search": "building, jewish, judaism, religion, star of david, temple"
	},
	{
		"icon": "sync",
		"search": "exchange, refresh, reload, rotate, swap"
	},
	{
		"icon": "sync-alt",
		"search": "exchange, refresh, reload, rotate, swap"
	},
	{
		"icon": "syringe",
		"search": "doctor, immunizations, medical, needle"
	},
	{
		"icon": "table",
		"search": "data, excel, spreadsheet"
	},
	{
		"icon": "table-tennis",
		"search": "ball, paddle, ping pong"
	},
	{
		"icon": "tablet",
		"search": "apple, device, ipad, kindle, screen"
	},
	{
		"icon": "tablet-alt",
		"search": "apple, device, ipad, kindle, screen"
	},
	{
		"icon": "tablets",
		"search": "drugs, medicine, pills, prescription"
	},
	{
		"icon": "tachometer-alt",
		"search": "dashboard, fast, odometer, speed, speedometer"
	},
	{
		"icon": "tag",
		"search": "discount, label, price, shopping"
	},
	{
		"icon": "tags",
		"search": "discount, label, price, shopping"
	},
	{
		"icon": "tape",
		"search": "design, package, sticky"
	},
	{
		"icon": "tasks",
		"search": "checklist, downloading, downloads, loading, progress, project management, settings, to do"
	},
	{
		"icon": "taxi",
		"search": "cab, cabbie, car, car service, lyft, machine, transportation, travel, uber, vehicle"
	},
	{
		"icon": "teeth",
		"search": "bite, dental, dentist, gums, mouth, smile, tooth"
	},
	{
		"icon": "teeth-open",
		"search": "dental, dentist, gums bite, mouth, smile, tooth"
	},
	{
		"icon": "temperature-high",
		"search": "cook, mercury, summer, thermometer, warm"
	},
	{
		"icon": "temperature-low",
		"search": "cold, cool, mercury, thermometer, winter"
	},
	{
		"icon": "tenge",
		"search": "currency, kazakhstan, money, price"
	},
	{
		"icon": "terminal",
		"search": "code, command, console, development, prompt"
	},
	{
		"icon": "text-height",
		"search": "edit, font, format, text, type"
	},
	{
		"icon": "text-width",
		"search": "edit, font, format, text, type"
	},
	{
		"icon": "th",
		"search": "blocks, boxes, grid, squares"
	},
	{
		"icon": "th-large",
		"search": "blocks, boxes, grid, squares"
	},
	{
		"icon": "th-list",
		"search": "checklist, completed, done, finished, ol, todo, ul"
	},
	{
		"icon": "theater-masks",
		"search": "comedy, perform, theatre, tragedy"
	},
	{
		"icon": "thermometer",
		"search": "mercury, status, temperature"
	},
	{
		"icon": "thermometer-empty",
		"search": "cold, mercury, status, temperature"
	},
	{
		"icon": "thermometer-full",
		"search": "fever, hot, mercury, status, temperature"
	},
	{
		"icon": "thermometer-half",
		"search": "mercury, status, temperature"
	},
	{
		"icon": "thermometer-quarter",
		"search": "mercury, status, temperature"
	},
	{
		"icon": "thermometer-three-quarters",
		"search": "mercury, status, temperature"
	},
	{
		"icon": "thumbs-down",
		"search": "disagree, disapprove, dislike, hand, social, thumbs-o-down"
	},
	{
		"icon": "thumbs-up",
		"search": "agree, approve, favorite, hand, like, ok, okay, social, success, thumbs-o-up, yes, you got it dude"
	},
	{
		"icon": "thumbtack",
		"search": "coordinates, location, marker, pin, thumb-tack"
	},
	{
		"icon": "ticket-alt",
		"search": "movie, pass, support, ticket"
	},
	{
		"icon": "times",
		"search": "close, cross, error, exit, incorrect, notice, notification, notify, problem, wrong, x"
	},
	{
		"icon": "times-circle",
		"search": "close, cross, exit, incorrect, notice, notification, notify, problem, wrong, x"
	},
	{
		"icon": "tint",
		"search": "color, drop, droplet, raindrop, waterdrop"
	},
	{
		"icon": "tint-slash",
		"search": "color, drop, droplet, raindrop, waterdrop"
	},
	{
		"icon": "tired",
		"search": "angry, emoticon, face, grumpy, upset"
	},
	{
		"icon": "toggle-off",
		"search": "switch"
	},
	{
		"icon": "toggle-on",
		"search": "switch"
	},
	{
		"icon": "toilet",
		"search": "bathroom, flush, john, loo, pee, plumbing, poop, porcelain, potty, restroom, throne, washroom, waste, wc"
	},
	{
		"icon": "toilet-paper",
		"search": "bathroom, halloween, holiday, lavatory, prank, restroom, roll"
	},
	{
		"icon": "toolbox",
		"search": "admin, container, fix, repair, settings, tools"
	},
	{
		"icon": "tools",
		"search": "admin, fix, repair, screwdriver, settings, tools, wrench"
	},
	{
		"icon": "tooth",
		"search": "bicuspid, dental, dentist, molar, mouth, teeth"
	},
	{
		"icon": "torah",
		"search": "book, jewish, judaism, religion, scroll"
	},
	{
		"icon": "torii-gate",
		"search": "building, shintoism"
	},
	{
		"icon": "tractor",
		"search": "agriculture, farm, vehicle"
	},
	{
		"icon": "trademark",
		"search": "copyright, register, symbol"
	},
	{
		"icon": "traffic-light",
		"search": "direction, road, signal, travel"
	},
	{
		"icon": "trailer",
		"search": "carry, haul, moving, travel"
	},
	{
		"icon": "train",
		"search": "bullet, commute, locomotive, railway, subway"
	},
	{
		"icon": "tram",
		"search": "crossing, machine, mountains, seasonal, transportation"
	},
	{
		"icon": "transgender",
		"search": "intersex"
	},
	{
		"icon": "transgender-alt",
		"search": "intersex"
	},
	{
		"icon": "trash",
		"search": "delete, garbage, hide, remove"
	},
	{
		"icon": "trash-alt",
		"search": "delete, garbage, hide, remove, trash-o"
	},
	{
		"icon": "trash-restore",
		"search": "back, control z, oops, undo"
	},
	{
		"icon": "trash-restore-alt",
		"search": "back, control z, oops, undo"
	},
	{
		"icon": "tree",
		"search": "bark, fall, flora, forest, nature, plant, seasonal"
	},
	{
		"icon": "trophy",
		"search": "achievement, award, cup, game, winner"
	},
	{
		"icon": "truck",
		"search": "cargo, delivery, shipping, vehicle"
	},
	{
		"icon": "truck-loading",
		"search": "box, cargo, delivery, inventory, moving, rental, vehicle"
	},
	{
		"icon": "truck-monster",
		"search": "offroad, vehicle, wheel"
	},
	{
		"icon": "truck-moving",
		"search": "cargo, inventory, rental, vehicle"
	},
	{
		"icon": "truck-pickup",
		"search": "cargo, vehicle"
	},
	{
		"icon": "tshirt",
		"search": "clothing, fashion, garment, shirt"
	},
	{
		"icon": "tty",
		"search": "communication, deaf, telephone, teletypewriter, text"
	},
	{
		"icon": "tv",
		"search": "computer, display, monitor, television"
	},
	{
		"icon": "umbrella",
		"search": "protection, rain, storm, wet"
	},
	{
		"icon": "umbrella-beach",
		"search": "protection, recreation, sand, shade, summer, sun"
	},
	{
		"icon": "underline",
		"search": "edit, emphasis, format, text, writing"
	},
	{
		"icon": "undo",
		"search": "back, control z, exchange, oops, return, rotate, swap"
	},
	{
		"icon": "undo-alt",
		"search": "back, control z, exchange, oops, return, swap"
	},
	{
		"icon": "universal-access",
		"search": "accessibility, hearing, person, seeing, visual impairment"
	},
	{
		"icon": "university",
		"search": "bank, building, college, higher education - students, institution"
	},
	{
		"icon": "unlink",
		"search": "attachment, chain, chain-broken, remove"
	},
	{
		"icon": "unlock",
		"search": "admin, lock, password, private, protect"
	},
	{
		"icon": "unlock-alt",
		"search": "admin, lock, password, private, protect"
	},
	{
		"icon": "upload",
		"search": "hard drive, import, publish"
	},
	{
		"icon": "user",
		"search": "account, avatar, head, human, man, person, profile"
	},
	{
		"icon": "user-alt",
		"search": "account, avatar, head, human, man, person, profile"
	},
	{
		"icon": "user-alt-slash",
		"search": "account, avatar, head, human, man, person, profile"
	},
	{
		"icon": "user-astronaut",
		"search": "avatar, clothing, cosmonaut, nasa, space, suit"
	},
	{
		"icon": "user-check",
		"search": "accept, check, person, verified"
	},
	{
		"icon": "user-circle",
		"search": "account, avatar, head, human, man, person, profile"
	},
	{
		"icon": "user-clock",
		"search": "alert, person, remind, time"
	},
	{
		"icon": "user-cog",
		"search": "admin, cog, person, settings"
	},
	{
		"icon": "user-edit",
		"search": "edit, pen, pencil, person, update, write"
	},
	{
		"icon": "user-friends",
		"search": "group, people, person, team, users"
	},
	{
		"icon": "user-graduate",
		"search": "cap, clothing, commencement, gown, graduation, person, student"
	},
	{
		"icon": "user-injured",
		"search": "cast, injury, ouch, patient, person, sling"
	},
	{
		"icon": "user-lock",
		"search": "admin, lock, person, private, unlock"
	},
	{
		"icon": "user-md",
		"search": "job, medical, nurse, occupation, physician, profile, surgeon"
	},
	{
		"icon": "user-minus",
		"search": "delete, negative, remove"
	},
	{
		"icon": "user-ninja",
		"search": "assassin, avatar, dangerous, deadly, sneaky"
	},
	{
		"icon": "user-nurse",
		"search": "doctor, midwife, practitioner, surgeon"
	},
	{
		"icon": "user-plus",
		"search": "add, avatar, positive, sign up, signup, team"
	},
	{
		"icon": "user-secret",
		"search": "clothing, coat, hat, incognito, person, privacy, spy, whisper"
	},
	{
		"icon": "user-shield",
		"search": "admin, person, private, protect, safe"
	},
	{
		"icon": "user-slash",
		"search": "ban, delete, remove"
	},
	{
		"icon": "user-tag",
		"search": "avatar, discount, label, person, role, special"
	},
	{
		"icon": "user-tie",
		"search": "avatar, business, clothing, formal, professional, suit"
	},
	{
		"icon": "user-times",
		"search": "archive, delete, remove, x"
	},
	{
		"icon": "users",
		"search": "friends, group, people, persons, profiles, team"
	},
	{
		"icon": "users-cog",
		"search": "admin, cog, group, person, settings, team"
	},
	{
		"icon": "utensil-spoon",
		"search": "cutlery, dining, scoop, silverware, spoon"
	},
	{
		"icon": "utensils",
		"search": "cutlery, dining, dinner, eat, food, fork, knife, restaurant"
	},
	{
		"icon": "vector-square",
		"search": "anchors, lines, object, render, shape"
	},
	{
		"icon": "venus",
		"search": "female"
	},
	{
		"icon": "venus-double",
		"search": "female"
	},
	{
		"icon": "venus-mars",
		"search": "Gender"
	},
	{
		"icon": "vial",
		"search": "experiment, lab, sample, science, test, test tube"
	},
	{
		"icon": "vials",
		"search": "experiment, lab, sample, science, test, test tube"
	},
	{
		"icon": "video",
		"search": "camera, film, movie, record, video-camera"
	},
	{
		"icon": "video-slash",
		"search": "add, create, film, new, positive, record, video"
	},
	{
		"icon": "vihara",
		"search": "buddhism, buddhist, building, monastery"
	},
	{
		"icon": "voicemail",
		"search": "answer, inbox, message, phone"
	},
	{
		"icon": "volleyball-ball",
		"search": "beach, olympics, sport"
	},
	{
		"icon": "volume-down",
		"search": "audio, lower, music, quieter, sound, speaker"
	},
	{
		"icon": "volume-mute",
		"search": "audio, music, quiet, sound, speaker"
	},
	{
		"icon": "volume-off",
		"search": "audio, ban, music, mute, quiet, silent, sound"
	},
	{
		"icon": "volume-up",
		"search": "audio, higher, louder, music, sound, speaker"
	},
	{
		"icon": "vote-yea",
		"search": "accept, cast, election, politics, positive, yes"
	},
	{
		"icon": "vr-cardboard",
		"search": "3d, augment, google, reality, virtual"
	},
	{
		"icon": "walking",
		"search": "exercise, health, pedometer, person, steps"
	},
	{
		"icon": "wallet",
		"search": "billfold, cash, currency, money"
	},
	{
		"icon": "warehouse",
		"search": "building, capacity, garage, inventory, storage"
	},
	{
		"icon": "water",
		"search": "lake, liquid, ocean, sea, swim, wet"
	},
	{
		"icon": "wave-square",
		"search": "frequency, pulse, signal"
	},
	{
		"icon": "weight",
		"search": "health, measurement, scale, weight"
	},
	{
		"icon": "weight-hanging",
		"search": "anvil, heavy, measurement"
	},
	{
		"icon": "wheelchair",
		"search": "accessible, handicap, person"
	},
	{
		"icon": "wifi",
		"search": "connection, hotspot, internet, network, wireless"
	},
	{
		"icon": "wind",
		"search": "air, blow, breeze, fall, seasonal, weather"
	},
	{
		"icon": "window-close",
		"search": "browser, cancel, computer, development"
	},
	{
		"icon": "window-maximize",
		"search": "browser, computer, development, expand"
	},
	{
		"icon": "window-minimize",
		"search": "browser, collapse, computer, development"
	},
	{
		"icon": "window-restore",
		"search": "browser, computer, development"
	},
	{
		"icon": "wine-bottle",
		"search": "alcohol, beverage, cabernet, drink, glass, grapes, merlot, sauvignon"
	},
	{
		"icon": "wine-glass",
		"search": "alcohol, beverage, cabernet, drink, grapes, merlot, sauvignon"
	},
	{
		"icon": "wine-glass-alt",
		"search": "alcohol, beverage, cabernet, drink, grapes, merlot, sauvignon"
	},
	{
		"icon": "won-sign",
		"search": "currency, krw, money"
	},
	{
		"icon": "wrench",
		"search": "construction, fix, mechanic, plumbing, settings, spanner, tool, update"
	},
	{
		"icon": "x-ray",
		"search": "health, medical, radiological images, radiology, skeleton"
	},
	{
		"icon": "yen-sign",
		"search": "currency, jpy, money"
	},
	{
		"icon": "yin-yang",
		"search": "daoism, opposites, taoism"
	}
];
