<div class="form-group">
	<label class="form-control-label" [for]="field">{{label | translate}}</label>

	<div class="{{error[field] && model[field] ? 'form-control' : ''}}" [class.is-invalid]="error[field]">
		<ngb-progressbar *ngIf="uploadResponse.status == 'progress'" showValue="true" type="info" [value]="uploadResponse.message"></ngb-progressbar>
		<input *ngIf="!model[field] && uploadResponse.status != 'progress'" [disabled]="disabled" (change)="fileSelected($event)" [name]="field" type="file" class="form-control" [class.is-invalid]="error[field]"
			placeholder="{{label | translate}}">
		<div *ngIf="model[field] && uploadResponse.status != 'progress'">
			<ng-container *ngIf="!rename">
				<a [href]="api.fileUrl+model[field]" target="_blank">{{name || model[field]}}</a> &nbsp; 
				<button (click)="delete()" class="btn btn-primary"><i class="fa fa-trash"></i> {{'sm-file.loschen' | translate}}</button>
				<button (click)="rename = true" class="btn btn-primary ml-2"><i class="fa fa-pen"></i> {{'Rename' | translate}}</button>					
			</ng-container>
			<ng-container *ngIf="rename">
				<mat-form-field class="example-full-width" appearance="outline" style="display: inline-block;width: calc(100% - 200px);">
					<mat-label>{{'File name...' | translate}}</mat-label>
					<input [(ngModel)]="name" [name]="rename" placeholder="{{'File name...' | translate}}" matInput>
				</mat-form-field>
				<button (click)="rename = false" class="btn btn-primary ml-2"><i class="fa fa-save"></i> {{'Cancel' | translate}}</button>
				<button (click)="updateName()" class="btn btn-success ml-2"><i class="fa fa-save"></i> {{'Save' | translate}}</button>	
			</ng-container>
		</div>
	</div>

	<div *ngIf="error[field]" class="invalid-feedback">{{error[field]}}</div>
</div>
