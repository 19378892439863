<!--div class="form-group">
	<label class="form-control-label" [for]="field">{{label | translate}}</label>

	<input multiple accept="image/*,.pdf" [disabled]="disabled" (change)="fileSelected($event)" [name]="field" type="file" class="form-control" [class.is-invalid]="error[field]" />
	<div *ngIf="error[field]" class="invalid-feedback">{{error[field]}}</div>


</div-->


<mat-label>{{label | translate}}</mat-label>
<mat-form-field class="example-full-width" appearance="outline" [class.mat-form-field-invalid]="error[field]">
	<input multiple accept="image/*,.pdf" [disabled]="disabled" (change)="fileSelected($event)" [name]="field" type="file" [disabled]="disabled" placeholder="{{label | translate}}" [required]="!!error[field]" style="width: 100%;">
	<input style="display:none" type="text" matInput>
	<mat-error *ngIf="error[field]">{{error[field] | translate}}</mat-error>
</mat-form-field>

<div [id]="'orderForm_'+field" [dragula]='"bag"' [(dragulaModel)]="reorderModel">
	<div class="card" *ngFor="let file of files;let index = index;">
		<input type="hidden" [value]="file.id" [alt]="index" />
		<div class="card-body">
			<div *ngIf="file.name && file.uploadResponse.status != 'progress'">
				<button (click)="delete(index)" class="btn btn-primary"><i class="fa fa-trash"></i> {{'sm-image-multiple.delete' | translate}}</button>
				<i class="fa fa-bars"></i> &nbsp;
				<a [href]="api.fileUrl+file.name" target="_blank">
					<img *ngIf="file.name.indexOf('.pdf') === -1" [src]="api.thumbUrl+file.name" [alt]="file.name" height="75" />
					<ng-container *ngIf="file.name.indexOf('.pdf') !== -1">{{file.name}}</ng-container>
				</a> &nbsp;
			</div>
			<ngb-progressbar *ngIf="file.uploadResponse.status == 'progress'" showValue="true" type="info" [value]="file.uploadResponse.message"></ngb-progressbar>
		</div>
	</div>
</div>
