<div>
  <h3 mat-dialog-title>
    <ng-container *ngIf="!data.id">{{'New' | translate}} </ng-container>{{'CV entry' | translate}}
    <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog" tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  
    <button mat-flat-button color="primary" (click)="save()">Save</button>
  </h3>
  
  <div class="container">
  
    <div mat-dialog-content>
      
      <sm-input label="Label" [(model)]="data" field="label" [(error)]="error"></sm-input>
      <sm-input label="Employer" [(model)]="data" field="employer" [(error)]="error"></sm-input>
      <sm-input label="Location" [(model)]="data" field="location" [(error)]="error"></sm-input>
      <sm-html label="Teaser" [(model)]="data" field="teaser" [(error)]="error"></sm-html>
      <sm-html label="Fulltext" [(model)]="data" field="fulltext" [(error)]="error"></sm-html>
      <sm-select label="CV entry type" [(model)]="data" field="f_cv_entry_type_id" [(error)]="error" [options]="options_f_cv_entry_type_id"></sm-select>
      <sm-checkbox-multiple label="Personal category" [(model)]="data" field="personal_category" [(error)]="error" [options]="options_personal_category"></sm-checkbox-multiple>
      <sm-date allowClear="true" label="Start date" [(value)]="data.start_date" [(model)]="data" field="start_date" [(error)]="error"></sm-date>
      <sm-date allowClear="true" label="End date" [(value)]="data.end_date" [(model)]="data" field="end_date" [(error)]="error"></sm-date>
      <button class="btn btn-primary float-right mb-2" (click)="createDocument()"><i class="fa fa-plus"></i> {{'Add document' | translate}}</button>
      <sm-select-multiple label="Documents" [(model)]="data" field="f_document_ids" [(error)]="error" [options]="options_f_document_ids"></sm-select-multiple>
      <sm-image-multiple label="Images" [(model)]="data" field="images" [(error)]="error"></sm-image-multiple>
  
  
    </div>
  </div>  
</div>
