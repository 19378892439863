<div>
  <h3 mat-dialog-title>
    <ng-container *ngIf="!data.id">{{'New' | translate}} </ng-container>{{'Category' | translate}}
    <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog" tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  
    <button mat-flat-button color="primary" (click)="save()">Save</button>
  </h3>
  
  <div class="container">
  
    <div mat-dialog-content>
      <sm-input label="Label" [(model)]="data" field="label" [(error)]="error"></sm-input>
      <sm-select-icon label="Icon" [(model)]="data" field="icon" [(error)]="error"></sm-select-icon>
    </div>
  </div>  
</div>
