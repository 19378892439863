import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ngbd-modal-content',
	templateUrl: './modal.html',
})
export class SmModalContent implements OnInit {
  constructor(    
		public dialogRef: MatDialogRef<SmModalContent>,
    @Inject(MAT_DIALOG_DATA) public data: any)  {

	}

	ngOnInit() {
	}
}
