<mat-form-field class="example-full-width" appearance="outline" [class.mat-form-field-invalid]="error[field]">
	<mat-label *ngIf="model[field]">{{label | translate}}</mat-label>
  <input class="d-none" [(ngModel)]="model[field]" [disabled]="disabled" [name]="field" [required]="!!error[field]" matInput>

  <button *ngIf="model[field]" class="btn btn-outline-secondary mr-2" (click)="open()">
    <i [class]="'text-dark fa fa-'+model[field]"></i> {{model[field]}}
  </button>
  <button *ngIf="!model[field]" class="btn btn-outline-primary" (click)="open()">
    Select icon
  </button>

	<mat-error *ngIf="error[field]">{{error[field] | translate}}</mat-error>
</mat-form-field>





<ng-template #modal>
	<div class="modal-header">
		<h4 class="modal-title">Select icon</h4>
		<button type="button" class="close" aria-label="Close" (click)="close()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body text-center" style="min-height: 600px;max-height: 85vh;overflow: auto;">
    <mat-form-field class="example-full-width" appearance="outline" [class.mat-form-field-invalid]="error[field]">
      <mat-label>{{'sm-select-icon.search' | translate}}</mat-label>
      <input [(ngModel)]="search" id="search" type="text" placeholder="{{'sm-select-icon.search' | translate}}" matInput>
    </mat-form-field>
    <div class="form-group">
      
    </div>
    <button *ngFor="let icon of icons" (click)="setIcon(icon.icon)" class="icon-item btn btn-outline-secondary" [class.d-none]="notInSearch(icon)" [title]="icon.icon+', '+icon.search">
      <i [class]="'text-dark fa fa-'+icon.icon"></i>
    </button>
	</div>
</ng-template>
