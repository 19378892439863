import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-document-edit-dialog',
  templateUrl: './documentEdit.component.html',
  styleUrls: ['./documentEdit.component.scss']
})
export class DocumentEditDialogComponent {
  @ViewChild('modal', { static: false }) modalItem: ElementRef;

	public data:any = {
		public: '0',
		shortdescription: '',
		longdescription: '',
  };
	error:any = {}
	success: boolean = false
	options_f_document_type_id:any = []
	options_personal_category:any = []

  constructor(
    public dialogRef: MatDialogRef<DocumentEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dataDialog: any,
    public api:ApiService
  ) {
    
  }


  async ngOnInit() {
		this.options_f_document_type_id = await this.api.request('document_type/forSelect', {});
		this.options_personal_category = await this.api.request('category/forSelect', {});
		this.data.f_user_id = this.api.user.id;
		this.getData();
	}

	async getData() {
    if (this.dataDialog.id != '0') {
			this.data = await this.api.request("document/getOne", {id: this.dataDialog.id});
		}
	}

	async save() {
		this.success = false;
		var resp:any = await this.api.request("document/save", this.data);
		if (resp.error) {
			this.error = resp.error;
		}
		if (resp.success) {
			this.success = true;
			this.data.id = resp.id;
			this.error = {};
			this.api.snackSuccess(this.api.trns('Document have been succesfully saved!'));
			if (this.dataDialog.afterSave) {
				this.dataDialog.afterSave(this.data);
			}
		}
	}

}
