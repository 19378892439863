import { Component, OnInit, Input, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { ApiService } from '../../../api.service';

@Component({
  selector: 'sm-select-multiple',
  templateUrl: './sm-select-multiple.component.html',
  styleUrls: ['./sm-select-multiple.component.scss']
})
export class SmSelectMultipleComponent implements OnInit {

	@Input() label:string;
	@Input() model:any;
	@Input() field:any;
	@Input() error: any = {};
	@Input() options:any;
	@Input() disabled: any = false;
	@Output() change = new EventEmitter();
	selectedItems:Array<any> = []
	dropdownList = [];
  dropdownSettings: any = {};
	
  constructor(private api:ApiService) { }

	ngOnChanges(changes: SimpleChanges) {
		this.updateSelect();
	}

  ngOnInit() {

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'value',
      textField: 'label',
			selectAllText: this.api.trns('sm-select-multiple.select-all'),
			unSelectAllText: this.api.trns('sm-select-multiple.unselect-all'),
      itemsShowLimit: 20,
      allowSearchFilter: true
		};
	}

	updateSelect() {
		if (!this.options) {
			this.options = [];
		}
		this.selectedItems = [];
		let splitted:Array<string> = this.model[this.field] ? this.model[this.field].split(",") : [];
		for(let option of this.options) {
			if (splitted.indexOf(option.value) != -1) {
				this.selectedItems.push(option.value);
			}
		}
	}
	
	update() {
		this.model[this.field] = this.selectedItems.join(",");
		this.change.emit(this.model[this.field]);
	}

}
