<div class="container">
  <h3 mat-dialog-title>{{data.title}}</h3>
  <div mat-dialog-content>
		<p [innerHTML]="data.text"></p>
  </div>
  <div mat-dialog-actions class="mb-1">
		<button *ngIf="!data.isConfirm" type="button" mat-flat-button (click)="dialogRef.close('close')">{{'modal.close' | translate}}</button>
		<button *ngIf="data.isConfirm" mat-flat-button color="warn" (click)="dialogRef.close('close')">{{'modal.cancel' | translate}}</button>
		<button *ngIf="data.isConfirm" type="button" mat-flat-button (click)="dialogRef.close('ok')">{{'modal.ok' | translate}}</button>
  </div>
</div>
