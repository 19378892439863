import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SmInputAutocompleteRoutingModule } from './sm-input-autocomplete-routing.module';
import { SmInputAutocompleteComponent } from './sm-input-autocomplete.component';

import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [SmInputAutocompleteComponent],
  imports: [
		NgbModule, TranslateModule, FormsModule,


    CommonModule,
    SmInputAutocompleteRoutingModule
	],
	exports: [
		SmInputAutocompleteComponent
	]
})
export class SmInputAutocompleteModule { }
