import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SmSelectMultipleRoutingModule } from './sm-select-multiple-routing.module';
import { SmSelectMultipleComponent } from './sm-select-multiple.component';

import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';


@NgModule({
  declarations: [SmSelectMultipleComponent],
  imports: [
		NgbModule, TranslateModule, FormsModule, MatSelectModule, MatFormFieldModule,

    CommonModule,
    SmSelectMultipleRoutingModule
	],
	exports: [
		SmSelectMultipleComponent
	]
})
export class SmSelectMultipleModule { }
