<mat-form-field class="example-full-width" appearance="outline" [class.mat-form-field-invalid]="!!error[field]">
	<mat-label>{{label | translate}}</mat-label>
	<input autocomplete="new-password" [(ngModel)]="model[field]" [disabled]="disabled" [name]="field" type="password" placeholder="{{label | translate}}" [required]="!!error[field]" matInput>
	<mat-error *ngIf="error[field]">{{error[field] | translate}}</mat-error>
</mat-form-field>

<mat-form-field class="example-full-width" appearance="outline" [class.mat-form-field-invalid]="!!error[field+2]">
	<mat-label>{{label | translate}} {{'sm-input-password.confirm' | translate}}</mat-label>
	<input [(ngModel)]="model[field+2]" [disabled]="disabled" [name]="field+2" type="password" placeholder="{{label | translate}} {{'confirm' | translate}}" [required]="!!error[field+2]" matInput>
</mat-form-field>
<mat-error style="font-size: 75%;margin-top: -15px;" *ngIf="error[field+2]">{{error[field+2] | translate}}</mat-error>
