<div>
  <h3 mat-dialog-title>
    <ng-container *ngIf="!data.id">{{'New' | translate}} </ng-container>{{'Image' | translate}}
    <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog" tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  
    <button mat-flat-button color="primary" (click)="save()">Save</button>
  </h3>
  
  <div class="container">
  
    <div mat-dialog-content>
      <sm-input label="Label" [(model)]="data" field="label" [(error)]="error"></sm-input>
      <sm-image label="Image" [(model)]="data" field="image" [(error)]="error"></sm-image>
      <sm-checkbox-multiple label="Category" [(model)]="data" field="f_category_id" [(error)]="error" [options]="options_f_category_id"></sm-checkbox-multiple>
    </div>
  </div>  
</div>
