import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'sm-radio',
  templateUrl: './sm-radio.component.html',
  styleUrls: ['./sm-radio.component.scss']
})
export class SmRadioComponent implements OnInit {

	@Input() label:string;
	@Input() model:any;
	@Input() field:any;
	@Input() error: any = {};
	@Input() options:any;
	@Input() disabled: any = false;
	@Output() change = new EventEmitter();

	public formGroupRadio:FormGroup;
	
  constructor() { 

	}

  ngOnInit() {
		this.formGroupRadio = new FormGroup({
			radio: new FormControl(this.model[this.field], [])
		})
	}

	runChange() {
		this.model[this.field] = this.formGroupRadio.value.radio;
		this.change.emit(this.model[this.field]);
	}
}
