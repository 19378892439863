import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SmInputRoutingModule } from './sm-input-color-routing.module';
import { SmInputColorComponent } from './sm-input-color.component';

import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';

@NgModule({
  declarations: [SmInputColorComponent],
  imports: [
		NgbModule, TranslateModule, FormsModule,ColorPickerModule,


    CommonModule,
    SmInputRoutingModule
	],
	exports: [
		SmInputColorComponent
	]
})
export class SmInputColorModule { }
