import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerModule } from "ngx-spinner";

import { MaterialModule } from "./material.module";
import { FeatherIconsModule } from "./components/feather-icons/feather-icons.module";
import { CvDisplayDialogComponent } from "./widgets/cvdisplay/cvdisplay.component";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { CvEntryDialogComponent } from "./widgets/cventry/cventry.component";
import { MatExpansionModule } from '@angular/material/expansion';

import {
	SmCheckboxMultipleModule,
	SmCheckboxModule,
	SmDateModule,
	SmFileModule,
	SmHiddenModule,
	SmHtmlModule,
	SmFileMultipleModule,
	SmImageModule,
	SmImageMultipleModule,
	SmInputNumericModule,
	SmInputPasswordModule,
	SmRadioModule,
	SmSelectModule,
	SmSelectMultipleModule,
	SmTextareaModule,
	SmDatetimeModule,
	SmInputModule,
  SmSelectIconModule
} from 'src/app/shared/inputs';
import { CventryAddDialogComponent } from "./widgets/cventryAdd/cventryAdd.component";
import { TranslateModule } from "@ngx-translate/core";
import { LanguageSelectorComponent } from "./components/language-selector/language-selector.component";
import { CategoryEditDialogComponent } from "./widgets/categoryEdit/categoryEdit.component";
import { ImageEditDialogComponent } from "./widgets/imageEdit/imageEdit.component";
import { DocumentEditDialogComponent } from "./widgets/documentEdit/documentEdit.component";
import { ProgressDialogComponent } from "./widgets/progress/progress.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatRadioModule } from "@angular/material/radio";

@NgModule({
  declarations: [ ProgressDialogComponent ,CvDisplayDialogComponent, CvEntryDialogComponent, CventryAddDialogComponent, LanguageSelectorComponent, CategoryEditDialogComponent, ImageEditDialogComponent, DocumentEditDialogComponent],
  imports: [
    MatButtonModule,
    MatIconModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatRadioModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    NgxSpinnerModule,
    TranslateModule,
    SmSelectIconModule,
    SmCheckboxMultipleModule,SmCheckboxModule,SmDateModule,SmFileModule,SmHiddenModule,SmHtmlModule,SmFileMultipleModule,SmImageModule,SmImageMultipleModule,SmInputNumericModule,SmInputPasswordModule,SmRadioModule,SmSelectModule,SmSelectMultipleModule,SmTextareaModule,SmDatetimeModule,SmInputModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    NgxSpinnerModule,
    MaterialModule,
    FeatherIconsModule,
    CvDisplayDialogComponent,
    CvEntryDialogComponent,
    LanguageSelectorComponent,
    CategoryEditDialogComponent,
    ImageEditDialogComponent,
    ProgressDialogComponent,
    DocumentEditDialogComponent,
  ],
})
export class SharedModule {}
