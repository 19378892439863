import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-progress-dialog',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss']
})
export class ProgressDialogComponent {
  @ViewChild('modal', { static: false }) modalItem: ElementRef;

	public data:any = [];
	error:any = {}
	success: boolean = false
	public options_yesno = [
		{ label: this.api.trns("Yes"), value: 'YES' },
		{ label: this.api.trns("No"), value: 'NO' }
	];

  constructor(
    public dialogRef: MatDialogRef<ProgressDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dataDialog: any,
    public api:ApiService
  ) {
    
  }


  ngOnInit() {
		this.getData();
	}

	async getData() {
		this.data = await this.api.request("assistant_category/fetchData", {});
		console.log('this.data',this.data);
	}

	async saveTodo(todo) {
		const resp:any = await this.api.request("user_attribute/saveAttribute", todo);
		if (resp.success) {
			this.api.snackSuccess(this.api.trns('Todo data have been succesfully saved!'));
		}
		else {
			this.api.snackError(this.api.trns('There was an error while saving todo data'));
		}
		this.getData();
		if (this.dataDialog.afterSave) {
			this.dataDialog.afterSave(this.data);
		}
	}

	allowSave(todo) {
		let allow = true;

		todo.type.map(type => {
			if (!todo.data[type]) {
				allow = false;
			}
		});

		return allow;
	}

}
