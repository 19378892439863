<div class="form-group">
	<label class="form-control-label" [for]="field">{{label | translate}}</label>

  <input
    id="typeahead-focus"
    type="text"
    class="form-control"
    [(ngModel)]="model[field]"
    [ngbTypeahead]="search"
    (focus)="focus$.next($any($event).target.value)"
    (click)="click$.next($any($event).target.value)"
    #instance="ngbTypeahead"
  />

	<div *ngIf="error[field]" class="invalid-feedback">{{error[field]}}</div>
</div>
