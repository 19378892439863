import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmModalContent } from './modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';


@NgModule({
  declarations: [SmModalContent],
	entryComponents: [SmModalContent],
  imports: [
		TranslateModule,
    CommonModule,
		MatButtonModule,
	],
	exports: [SmModalContent]
})
export class SmModalModule {
	getModal() {
		return SmModalContent;
	}
}
