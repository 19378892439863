import { Component, OnInit, Input, SimpleChanges, EventEmitter, Output, ElementRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../../api.service';
import { icons } from './icons';

@Component({
	selector: 'sm-select-icon',
	templateUrl: './sm-select-icon.component.html',
	styleUrls: ['./sm-select-icon.component.scss']
})
export class SmSelectIconComponent implements OnInit {

	@Input() label: string;
	@Input() model: any;
	@Input() disabled: any = false;
	@Input() field: any;
	@Input() error: any = {};
	@Output() change = new EventEmitter();
  @ViewChild('modal', { static: false }) modalItem: ElementRef;

  public icons = icons;
  public search = "";

	constructor(private api:ApiService, private modalService: NgbModal) { }

	ngOnInit() {
    // let newIcons = icons.map(icon => {
    //   let metaData = meta[icon];
    //       return {
    //         icon,
    //         search: metaData?.search?.terms.join(", ")
    //       }
    // });
    // console.log('',newIcons);
	}

  open() {
    this.modalService.open(this.modalItem, {windowClass: 'documentDisplay'});
		setTimeout(()=>{
			document.getElementById("search").focus();
		}, 300)
  }

	setIcon(icon) {
		let prev = this.model[this.field];
		this.model[this.field] = icon;
		if (this.model[this.field] != prev) {
			this.runChange();
		}
    this.modalService.dismissAll();
	}

	runChange() {
		this.change.emit(this.model[this.field]);
	}

  close() {
    this.modalService.dismissAll();
  }

  notInSearch(icon) {
    if (!this.search) {
      return false;
    }
    return `${icon.name}, ${icon.search}`.indexOf(this.search.toLowerCase()) === -1;
  }
}
