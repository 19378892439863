import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import {Observable, Subject, merge, OperatorFunction} from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

@Component({
  selector: 'sm-input-autocomplete',
  templateUrl: './sm-input-autocomplete.component.html',
  styleUrls: ['./sm-input-autocomplete.component.scss']
})
export class SmInputAutocompleteComponent implements OnInit {
	@Input() label:string;
	@Input() model:any;
	@Input() field:any;
	@Input() options:any = [];
	@Input() error: any = {};
	@Input() disabled: any = false;

  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  @ViewChild('instance', {static: true}) instance: NgbTypeahead;

  search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.options
        : this.options.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 1000))
    );
  }

  constructor() { }

  ngOnInit() {
  }

}
