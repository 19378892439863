<div class="form-group">
	<label class="form-control-label" [for]="field">{{label | translate}}</label>

	<div class="grid">
		<div class="row">
			<div class="col">
				<div class="input-group datepicker-input">
					<input class="form-control" [maxDate]="maxDate" [minDate]="minDate" (dateSelect)="setNewDate()" (click)="d.toggle()" placeholder="yyyy-mm-dd" [name]="field" [(ngModel)]="dateContainer" [class.is-invalid]="error[field]" readonly ngbDatepicker #d="ngbDatepicker">
					<button class="input-group-addon" (click)="d.toggle()" type="button">
						<span class="fa fa-calendar"></span>
					</button>
					<div *ngIf="error[field]" class="invalid-feedback">{{error[field]}}</div>
				</div>
			</div>
			<div class="col timeCol">
				<ngb-timepicker (click)="setNewDate()" (change)="setNewDate()" [(ngModel)]="time"></ngb-timepicker>
			</div>
		</div>
	</div>
</div>

