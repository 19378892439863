<div>
  <h3 mat-dialog-title>
    {{'Progress' | translate}}
    <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog" tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>  
  </h3>
  
  <div class="container">
    <div mat-dialog-content>
      
      <div *ngFor="let category of data" class="mb-4">

        <h2>{{category.name}}</h2>        
        <mat-accordion>
          <mat-expansion-panel *ngFor="let todo of category.todo" hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title style="width: 80%;">{{todo.description}}</mat-panel-title>
              <mat-panel-description>
                <mat-icon *ngIf="todo.dataExists==='1'">done</mat-icon>
                &nbsp;
                <div *ngIf="todo.verified==='1'" class="badge col-green">{{'Verified' | translate}}</div>
              </mat-panel-description>
            </mat-expansion-panel-header>

            <sm-radio *ngIf="todo.type.indexOf('YESNO') !== -1" label="Yes / no" [(model)]="todo.data" field="YESNO" [options]="options_yesno"></sm-radio>
            <sm-textarea *ngIf="todo.type.indexOf('DESCRIBE') !== -1" label="Describe" [(model)]="todo.data" field="DESCRIBE"></sm-textarea>
            <sm-file *ngIf="todo.type.indexOf('FILE') !== -1" label="Upload" [(model)]="todo.data" field="FILE"></sm-file>

            <mat-action-row>
              <button mat-button [disabled]="!allowSave(todo)" (click)="saveTodo(todo)" color="primary">{{'Save' | translate}}</button>
            </mat-action-row>
          </mat-expansion-panel>

        </mat-accordion>
        </div>


    </div>
  </div>  
</div>
