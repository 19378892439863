import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SmInputRoutingModule } from './sm-input-routing.module';
import { SmInputComponent } from './sm-input.component';

import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';


@NgModule({
  declarations: [SmInputComponent],
  imports: [
		NgbModule, TranslateModule, FormsModule, MatFormFieldModule, MatInputModule,


    CommonModule,
    SmInputRoutingModule
	],
	exports: [
		SmInputComponent
	]
})
export class SmInputModule { }
