import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SmImageMultipleRoutingModule } from './sm-image-multiple-routing.module';
import { SmImageMultipleComponent } from './sm-image-multiple.component';

import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { DragulaModule } from 'ng2-dragula';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [SmImageMultipleComponent],
  imports: [
		NgbModule, TranslateModule, FormsModule, DragulaModule, MatFormFieldModule, MatInputModule,


    CommonModule,
    SmImageMultipleRoutingModule
	],
	exports: [
		SmImageMultipleComponent
	]
})
export class SmImageMultipleModule { }
