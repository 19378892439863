import { Component, OnInit, Input } from "@angular/core";
import { ApiService } from "src/app/api.service";
import { LanguageService } from "src/app/core/service/language.service";

@Component({
  selector: "app-language-selector",
  templateUrl: "./language-selector.component.html",
  styleUrls: ["./language-selector.component.scss"],
})
export class LanguageSelectorComponent implements OnInit {
  flagvalue;
  langStoreValue: string;
  defaultFlag: string;
  countryName;

  constructor(
    public api: ApiService,
    public languageService: LanguageService,
    ) {}

  async ngOnInit() {
    await this.api.getLanguages();

    this.langStoreValue = localStorage.getItem('lang');
    const val = this.api.languages.filter((x) => x.code === this.langStoreValue);
    this.countryName = val.map((element) => element.name);
    if (val.length === 0) {
      this.flagvalue = this.api.languages.find(e => e.is_default === "1")?.image;
    } else {
      this.flagvalue = val.map((element) => element.image);
    }
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.langStoreValue = lang;
    this.languageService.setLanguage(lang);
  }
}
