import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SmInputMultipleRoutingModule } from './sm-input-multiple-routing.module';
import { SmInputMultipleComponent } from './sm-input-multiple.component';

import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { DragulaModule } from 'ng2-dragula';

@NgModule({
  declarations: [SmInputMultipleComponent],
  imports: [
		NgbModule, TranslateModule, FormsModule, DragulaModule,


    CommonModule,
    SmInputMultipleRoutingModule
	],
	exports: [
		SmInputMultipleComponent
	]
})
export class SmInputMultipleModule { }
