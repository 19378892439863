import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { DocumentEditDialogComponent } from '../documentEdit/documentEdit.component';

@Component({
  selector: 'app-cventry',
  templateUrl: './cventry.component.html',
  styleUrls: ['./cventry.component.scss']
})
export class CvEntryDialogComponent {
  @ViewChild('modal', { static: false }) modalItem: ElementRef;

	public data:any = {
		teaser: '',
		fulltext: '',
    start_date: this.api.date(),
    end_date: this.api.date(),
  };
  public documentData:any = {};
	errorDocument:any = {}
	error:any = {}
	success: boolean = false
	options_f_cv_entry_type_id:any = []
	options_personal_category:any = []
	options_f_document_ids:any = []
  options_f_document_type_id:any = []

  constructor(
    public dialogRef: MatDialogRef<CvEntryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dataDialog: any,
    public api:ApiService
  ) {
    
  }


  ngOnInit() {
		this.getData();
	}

	async getData() {
		this.options_f_cv_entry_type_id = await this.api.request('cv_entry_type/forSelect', {});
		this.options_personal_category = await this.api.request('category/forSelect', {});
		this.options_f_document_ids = await this.api.request('document/forSelect', {});
    this.options_f_document_type_id = await this.api.request('document_type/forSelect', {});

    if (this.dataDialog.id != '0') {
			this.data = await this.api.request("cv_entry/getOne", {id: this.dataDialog.id});
		}
	}

	async save() {
		this.success = false;
		var resp:any = await this.api.request("cv_entry/save", this.data);
		if (resp.error) {
			this.error = resp.error;
		}
		if (resp.success) {
			this.success = true;
			this.data.id = resp.id;
			this.error = {};
			this.api.snackSuccess(this.api.trns('Cv have bees succesfully saved!'));
			if (this.dataDialog.afterSave) {
				this.dataDialog.afterSave(this.data);
			}
		}
	}

  createDocument() {
    this.documentData = {};
		const dialog = this.api.dialog.open(DocumentEditDialogComponent, {
      data: {
        afterSave: async (docData) => {
					this.options_f_document_ids = await this.api.request('document/forSelect', {});
					let newDocs = this.data.f_document_ids.split(",");
					newDocs.push(docData.id);
					this.data.f_document_ids = newDocs.join(",");
					dialog.close();
				}
      },
    });
  }

  async saveDocument() {
    console.log('this.documentData',this.documentData);
    this.success = false;
		var resp:any = await this.api.request("document/save", this.documentData);
		if (resp.error) {
			this.errorDocument = resp.error;
		}
		if (resp.success) {
      //this.api.modalService.dismissAll();
      this.options_f_document_ids = await this.api.request('document/forSelect', {});
      let docs = this.data.f_document_ids.split(",");
      docs.push(resp.id);
      this.data.f_document_ids = docs.join(",");
		}
  }

}
